import React, {FC, useContext, useState, useEffect} from "react"
import {Context} from "../index"
import {observer} from "mobx-react-lite"
import {RegistrationResponse} from "../models"
import { Axios, AxiosError, AxiosResponse } from "axios";

const Authorization : FC = () => {
    const [registrationForm, setRegistrationForm] = useState<boolean>(false)
    const[email, setEmail] = useState<string>('')
    const[password, setPassword] = useState<string>('')
    const[passwordconfirm, setPasswordconfirm] = useState<string>('')
    const[login, setLogin] = useState<string>('') 
    
    const {store} = useContext(Context);
    const [msgerr, setMsgerr] = useState<string>('')
    const [iserrauth, setIserrauth] = useState<boolean>(false)

    

    async function clickAuth(email:string, password:string){
        await store.login(email, password)
        setMsgerr(store.isMSGerr)
        setIserrauth(store.isERRauth)
        console.log('msgerr')
    }

    async function start() {
        await store.checkAuth()
        setMsgerr(store.isMSGerr)
        setIserrauth(store.isERRauth)
    }

    async function logout() {
        await store.logout()
        setMsgerr(store.isMSGerr)
        setIserrauth(store.isERRauth)
    }

    useEffect(() => {
        if(localStorage.getItem('access_token')){
            start()
        }
    }, [])

    // store.registration(email, password)
    async function regisration(){
        setIserrauth(true)
        console.log(email)
        if(password === passwordconfirm){
            const result = await store.registration(email, password,passwordconfirm, login)
            if(result?.data.err == 1){
                setMsgerr(result.data.msg)
                setIserrauth(true)
            }
            if(result?.data.err == 0){
                setMsgerr(result.data.msg)
                setIserrauth(true)
            }
            
        }

    }
    return(
        
        <div>
            { !store.isAuth && <div>
            <h1 className="mb-2">Авторизация</h1>
            <form action="">
                <input 
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    type="text" 
                    id="email" 
                    className='pl-5 text-blue-800 rounded-lg w-full mb-2' 
                    placeholder='email' />
            {registrationForm && <>
                <input 
                                onBlur={()=>console.log('jjjj')}
                                onChange={e => setLogin(e.target.value)}
                                value={login}
                                type="text" 
                                id="login" 
                                className='pl-5 text-blue-800 rounded-lg w-full mb-2' 
                                placeholder='Логин' />
            </>}
                <input 
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    type="password"  
                    id="pass"  
                    className='pl-5 text-blue-800 rounded-lg w-full mb-2' 
                    placeholder='Пароль' />
                {registrationForm && <>
                                <input 
                                onChange={e => setPasswordconfirm(e.target.value)}
                                value={passwordconfirm}
                                type="password"  
                                id="passconfirm"  
                                className='pl-5 text-blue-800 rounded-lg w-full mb-2' 
                                placeholder='Повторите пароль' />
</>
                }

                {iserrauth && <p className="text-xs text-fuchsia-400 mt-2 mb-3">{msgerr}</p>}
                {!registrationForm &&<>
                <button
                    onClick={() => clickAuth(email,password)}
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration050 ease-in-out"
                >Войти</button>
                <button
                    onClick={() => setRegistrationForm(true)}
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration050 ease-in-out"
                >Регистрация</button>
                </>}
                {registrationForm && <>
                <button
                    onClick={() =>regisration()}
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="ml-2 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration050 ease-in-out"
                >Создать аккаунт</button>
                <button
                    onClick={() => setRegistrationForm(false)}
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md  hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration050 ease-in-out"
                >Авторизация</button>
               </> }
            </form></div>
            }
            {store.isAuth && 
            <div 
            onClick={() => logout()}
            className="mt-2 mb-2 flex flex-wrap items-stretch " title="Выход"><div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer hover:text-fuchsia-500">
            <path  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
          </svg></div>
          
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                </div>
                <div 
                className="text-fuchsia-500 self-end inline-block align-text-bottom align-middle"><span className="text-fuchsia-500 self-end inline-block align-text-bottom align-middle font-bold">@{store.user.login}</span>
                </div>
            </div>
            }

        </div>
        
    )
}

export default observer(Authorization);
// export default Authorization;