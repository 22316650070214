import React ,{useContext, useEffect, useState, FC} from "react"
import {teg_item} from "../data/teg_item"
import {nois_item} from "../data/nois_item"
import {Search} from "./Search"
import SearchResultComponent from "./SearchResultComponent"
import {Item_teg} from "./Item_teg"
import Add_nois_item from "./Add_nois_item"
import {StatisticsComponent} from './GetStatistics'
import {search_teg_item} from "../data/serch_teg_add"
import {Ra_teg, Ra_nois_line, SourceNoisResponce, GrpAdd, MyGrp} from '../models'
import SearchResult from './SearchResult'
import {SelectedNoisItemData} from '../data/SelectedNoisItemData'
import { SelectedNoisItem } from "./SelectedNoisItem"
import axios from "axios"
import Authorization from "./Authorization"
import { Context } from ".."
import {observer} from "mobx-react-lite"
import TegsService from "../services/TegsService"
import { SHA256 } from 'crypto-js';
import {SourceNoisService} from "../services/SourceNoisService"
import Bargroup from "./Bargroupnois"
import BarSearhResult from "./BarSearhResult"
import MessageADDNoisitem from "../message"



export default observer(function Conteiner(){
    const [tegs_for_filtr, setTegs_for_filtr] = useState(Array<Ra_teg>)
    const [selected_item_nois, setSelected_item_nois] = useState(Array<SourceNoisResponce>) // выбранные ИШ
    const [selected_items_rollup, setSelected_items_rollup] = useState(Array<boolean>) // выбранные ИШ свернуть
    const [tegs_Found, setTegs_Found] = useState(Array<Ra_teg>)
    // const [searchResultData, SetSearchResultData] = useState(Array<SourceNoisResponce>)
    const [hash, setHash] = useState('');
    //BARGRP
    const [barGroup,SetBarGroup] = useState<boolean>(false)
    const [loadBar, setLoadBar] = useState<boolean>(false)
    const [loadBarErr, setLoadBarErr] = useState<boolean>(false)
    const [mesageGRP, setMessageGRP] = useState<string>('')
    const message = new MessageADDNoisitem().getLang('ru')
    const [selectMenuState, SetSelectMenuState] = useState<number>(1) // выбор меню в результатах поиска
    const [mygrpState, SetMygrpState] = useState(Array<MyGrp>)
    const [paginationSkip, setPaginationSkip] = useState<number>(0)
    const [paginationMyIshSkip, setPaginationMyIshSkip] = useState<number>(0)
    const [paginationSearchResultSkip, setPaginationSearchResultSkip] = useState<number>(0)
    const [getMyGrpErr, setGetMyGrpErr] = useState<boolean>(false)  // мои grp
    const [getMyGrpLoad, setGetMyGrpLoad] = useState<boolean>(false)  // мои grp
    const [paginationTrue, setPaginationTrue] = useState<boolean>(false) // paginationGRP
    const [paginationMyIshTrue, setPaginationMyIshTrue] = useState<boolean>(false) // paginationMy Ish
    const [paginationSearchResult, setPaginationSearchResult] = useState<boolean>(false) // paginationMy pagination SearchResult
    
    

    const [noisSelectedGRP, setNoisSelectedGRP] = useState(Array<SourceNoisResponce>)  // отобразить ИШ выбранной группы
    const [selectedGRP, setSelectedGRP] = useState<boolean>(false)
    const [selectedGRPNameState, setSelectedGRPNameState] = useState<string>('')

    const [myIshState, SetMyIshState] = useState(Array<SourceNoisResponce>)  // мои ИШ
    const [GetMyIshErr, setGetMyIshErr] = useState<boolean>(false)  // мои ИШ

    const [textforSearch, setTextforSearch] = useState<string>('')

    

async function selectMenu(nMenu:number){ // выбор меню в результатах поиска
   await SetSelectMenuState(nMenu)
    

}    


//tegAdd
const {store} = useContext(Context);

function boolInSelected_item_nois(id:string):boolean{ //выделение ИШ включенных в группу
    let bool = false
    for(let i = 0;i<selected_item_nois.length;i++){
        if(selected_item_nois[i].id === id){
            bool =  true}

    }

    return bool
}

async function findsourceNoisbyTegs(tegs:Array<Ra_teg>) {
    console.log('SourceNoisService')
    let namesTag:string[] = []
    for(let teg_i=0;teg_i<tegs.length; teg_i++){
        namesTag.push(tegs[teg_i].name)
    }
    const reponce = await SourceNoisService.findBytegs(namesTag)
    // await SetSearchResultData([reponce.data])

}

async function getPopularTegs(){
    const response = await TegsService.getPopularTegs(6,0)
    await setTegs_Found(response.data)

}

async function getMyGrp(){

    setGetMyGrpErr(false)
    setGetMyGrpLoad(true)
    try{
        const response = await SourceNoisService.getMyGroup(10,paginationSkip)
        
        if(response.status == 200){ 
            if(response.data.length <10){
                setPaginationTrue(false)
            }
            else{setPaginationTrue(true)
                setPaginationSkip(paginationSkip+10)}

            for(let i=0; i < response.data.length; i++){
                await SetMygrpState(current => [...current, response.data[i]])
            }
            
        }
        else{
            setGetMyGrpErr(true)
        }
    }
    catch(e:any){
        setGetMyGrpLoad(false)
        setGetMyGrpErr(true)

    }

}

async function getMyIsh() {
    setGetMyIshErr(false)
    setGetMyGrpLoad(true)
    setPaginationMyIshTrue(false)

    try{
        let limit = 10
        const response = await SourceNoisService.getMyIsh(limit,paginationMyIshSkip)
        console.log(response.data)
        if(response.data){
            if(response.status == 200){ 
                if(response.data.length < limit ){
                    setPaginationMyIshTrue(false)
                }
                else{setPaginationMyIshTrue(true)
                    setPaginationMyIshSkip(paginationMyIshSkip+limit)}

                for(let i=0; i < response.data.length; i++){
                    
                    await SetMyIshState(current => [...current, response.data[i]])
                }
                
            }
            else{
                setGetMyGrpErr(true)
            }
        }else{
            setPaginationMyIshTrue(false)
        }
    }
    catch(e:any){
        setGetMyGrpLoad(false)
        setGetMyIshErr(true)

    }

}

useEffect(() => {
     getPopularTegs()
     getMyGrp()
     getMyIsh()

   
}, [])


const add_teg_click = async(addTag:Ra_teg) => {    // клик по тэгу
    let true_add  = true
    for(let i = 0;i<tegs_for_filtr.length;i++){
        if(tegs_for_filtr[i]._id === addTag._id){true_add=false}
    }
    if(true_add){
        await setTegs_for_filtr(current => [...current, addTag])
        store.updateloadTrue(true) 
           


    }
    
}

const rm_teg_click = async(addTag:Ra_teg) => {    // клик по тэгу
    setTegs_for_filtr(tegs_for_filtr.filter(p => p._id !== addTag._id));
    store.updateloadTrue(true) 
}
//regAddEnd


    // const select_item_n_Click = async(selItem:SourceNoisResponce) => {
    //     let true_add  = true
    //     for(let i = 0;i<selected_item_nois.length;i++){
    //         if(selected_item_nois[i].id === selItem.id){true_add=false}
    //     }
    //     if(true_add){
    //         await setSelected_item_nois(current => [...current, selItem])
            

    //     }
        
    // }

    function select_Check  (id:string){
        let true_sell  = false
        for(let i = 0;i<selected_item_nois.length;i++){
            if(selected_item_nois[i].id === id){true_sell=true}
        }
        if(true_sell){        
            return('border-slate-300')
        }else{return('border-slate-600')}
        
    }

    const select_teg_click = async(selectTeg:Ra_teg, action:number) => {    // Выбор Тегов
        if(action===1){
            add_teg_click(selectTeg)
        }
        if(action===0){
            rm_teg_click(selectTeg)
        }
        
    }

    const selectMyGroup = async(myGroup:MyGrp)=>{
        try{
            setSelectedGRPNameState(myGroup.nameGrp)
            setSelectedGRP(true)
            const responce = await SourceNoisService.getIsFromGRP(myGroup.id)
            setNoisSelectedGRP(responce.data)
            SetSelectMenuState(4)
        }
        catch(e:any){
            console.log('loadNoisGRP err')
        }

        console.log('Getgroup')
    }

    const selectNoiseClick = async(selectedNoisSource:SourceNoisResponce)=>{

        let true_add  = true
        console.log(selected_item_nois)
        for(let i = 0;i<selected_item_nois.length;i++){
            console.log('id=',selected_item_nois[i].id, '   id=',  selectedNoisSource.id)
            if(selected_item_nois[i].id === selectedNoisSource.id){
                true_add=false
            
            }
        }
        if(true_add){
            await setSelected_item_nois(current => [selectedNoisSource, ...current])
        }
        

    }

    async function deleteNoiseSourcefromBasket(idNoisSource:string){

        setSelected_item_nois(selected_item_nois.filter(p => p.id !== idNoisSource));
    }

    async function messageBarGRP(text:string){
        await setLoadBar(true)
        setMessageGRP(text)
        setTimeout(function cb1() {
            setMessageGRP('')
            setLoadBar(false)
          }, 7000)

        
    }

    async function getArchive(name:string){ // New group Ish Add
        if(name.length>3){
            await setLoadBarErr(false)
            await setLoadBar(true)
            await SetBarGroup(false)
            let noisId:Array<string> = []
            for(let i=0; i < selected_item_nois.length;i++) {
                noisId.push(selected_item_nois[i].id)
            }
            const data:GrpAdd = {
                name:name ,
                noisIdArr:noisId
            }
            await messageBarGRP(message.MESSAGEGRPCREATION)
            try{
                console.log('error Server')
                const response = await SourceNoisService.group(data)
                
                if(response.status == 200){
                    await messageBarGRP(message.GRPADDSUCCESS)
                    await getMyGrp()
                    
                }
                
            }
            catch(e:any){
                console.log('error Server')
                await setLoadBarErr(true)
                await messageBarGRP(message.ERRORCONNECTGETLINK)

            }
            
        }
        
    }
    async function paginationMyGrpClic() {    

        await getMyGrp()
    }

    async function paginationSearchResultClic() {    

        await getMyGrp()
    }

    async function paginationyMyIshClic() {    

        await getMyIsh()
    }

    async function searchText(text:string){
            setTextforSearch(text)
            console.log('====',text)

            // try{
            //     const response = await SourceNoisService.searchByText(text)
                
            //     // if(response.status == 200){            
                   
                    
            //     // }
                
            // }
            // catch(e:any){
            //     console.log('error Server')
            //     await setLoadBarErr(true)
            //     await messageBarGRP(message.ERRORCONNECTGETLINK)

            // }    

    }

    async function reloadData(){
        await SetMyIshState([])
        await getMyIsh()

    }

    return (
        <div className="md:container md:mx-auto text-orange-400 px-4 py-4 my-4 mx-4"> 
        
            <div className="grid grid-cols-2 items-end">
                <div><img src="logo.gif" className="float-left mx-4 my-4 self-center"></img><div className="text-violet-400 text-4xl align-middle ">noiseclub.ru</div>
                    <div>
                    <StatisticsComponent/>
                    </div>
                </div>
                <div>   </div>
                
            </div>


            <div className="flex flex-row flex-nowrap">
                <div className="basis-1/4  mx-4">
                    
                    <div className="relative ">
                        <Search Selected={select_teg_click} searchTextProps={searchText}></Search>
                    </div>
                    {/* <div className="flex flex-wrap mb-2">
                        {tegs_for_filtr.map(teg_item => <Item_teg pref="tg1_" tegs={teg_item} key={teg_item._id} onClick_={rm_teg_click} addclass='text-violet-300 border border-violet-500 hover:bg-gray-600 '></Item_teg>  ) }
                    </div>
                    <div className="flex flex-wrap">
                        {tegs_Found.map(teg_item => <Item_teg pref="tg2_" tegs={teg_item} key={teg_item._id} onClick_={add_teg_click}></Item_teg>  ) }
                    </div> */}
                    <div>
                        <div><Authorization></Authorization></div>
                        {store.isAuth &&
                            <div><Add_nois_item selectMenuMyIsh = {selectMenu} reload ={ reloadData}></Add_nois_item></div>
                        }
                    </div>
                    
                   
                </div>
                <div className="grid grid-cols-2 gap-4 w-full content-start relative" >
                <div className="  absolute h-10 -top-10 left-5"><BarSearhResult selectedGRPName={selectedGRPNameState} selected = {selectMenuState} selectMenu={selectMenu}/></div>
                            <><SearchResultComponent mygrp={mygrpState} 
                                                    selectMenu={selectMenuState} 
                                                    includeBoolBasket = {boolInSelected_item_nois} 
                                                    tegsForfound = {tegs_for_filtr} 
                                                    selectNoiseSource = {selectNoiseClick} 
                                                    selectGroup={selectMyGroup} 
                                                    slectedGRPviwe={noisSelectedGRP}
                                                    myIsh={myIshState}
                                                    myIshloaderr={GetMyIshErr}
                                                    paginationGRPTrue = {paginationTrue}
                                                    paginationMyGrpClic={paginationMyGrpClic}
                                                    paginationMyIshTrue = {paginationMyIshTrue}
                                                    paginationMyIshClic={paginationyMyIshClic}
                                                    paginationSearchResult = {paginationSearchResult}
                                                    paginationSearchResultClic={paginationSearchResultClic}
                                                    textSearch={textforSearch}
                                                    /></>
                    <div className="basis-1/2  mx-4 relative">
                        <div className="w-full  absolute h-10 -top-10">
                            
                        {!loadBar  && selected_item_nois.length > 1 && 
                            <button onClick={e => SetBarGroup(!barGroup)} title='набор'
                                className="  text-orange-500 hover:text-orange-300 font-bold  rounded inline-flex items-center w-5 absolute right-1 top-1 title={pub.title} ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                </svg>

                            </button>}
                            {loadBar && <div className="px-4 text-sm text-violet-200 border rounded-lg border-violet-400 bg-violet-900">{mesageGRP}</div>}
                            {selected_item_nois.length > 1 && barGroup && <Bargroup getArchiveAction={getArchive}></Bargroup>}
                        </div>
                        {selected_item_nois.length > 0 && <SelectedNoisItem roll={0} deleteClick={deleteNoiseSourcefromBasket} key={selected_item_nois[0].id} SelectedNoisItem={selected_item_nois[0]}/>}
                        {selected_item_nois.slice(1,selected_item_nois.length).map(item => <SelectedNoisItem roll={1} deleteClick={deleteNoiseSourcefromBasket} key={item.id} SelectedNoisItem={item}/>)}
                    </div>
                </div>
            </div>
        </div>
    )

}
)
