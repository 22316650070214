class RuOsipov {

    static assembling(arr:Array<Number>){
        interface tab{
            [key: string]: number;
            '_63':number
            '_125':number
            '_250':number
            '_500':number
            '_100':number
            '_2000':number
            '_4000':number
            '_8000':number
        }
        const tab1 = [26.2, 16.1, 8.6, 3.2, 0, -3.2, -1, 1.1]
        // const tab1:tab = {
        //     '_63':26.2,
        //     '_125':16.1,
        //     '_250':8.6,
        //     '_500':3.2,
        //     '_100':0,
        //     '_2000':-3.2,
        //     '_4000':-1,
        //     '_8000':1.1
        // }
        console.log('arr')
        console.log(arr)
        try{        
            let sum = 0
            for(let i=0; i<8;i++){

                sum += Math.pow(10,
                    (0.1 * (Number(arr[i]) - tab1[i]))
                    )
                console.log(arr[i], '- ', tab1[i])
            }
            console.log('sum', sum)
            let dL = 10 * Math.log10(sum)
            console.log('dL')
            console.log(dL)
            return dL 
        }
        catch{
            return false
        }
    }
    

    static ptow(ish_l:number|string,ish_w:number|string, L:number, r:number, amegaSTR:string, delta:string = '10'){ //Осипов Ф 16.16 стр 298
        try{
            let pi:number = 3.14159265359
            let amega:number = 0
            switch(amegaSTR){
                case '2pi': 
                    amega = 2 * pi;
                    break;
                case 'pi': 
                    amega = pi;
                    break;
                case 'pi/2': 
                    amega = pi/2;
                    break;
            }
            let lw:number
            if(ish_l>ish_w){
                lw = Number(ish_l)
            }
            else{
                lw = Number(ish_w)
            }
            let Lp:number = 0
            if(r> 2*lw){
                Lp = L + 20 * Math.log10(r) + 10 * Math.log10(amega)
            }
            if(r<= 2*lw){
                Lp = L + 10 * Math.log10(Number(ish_l) * Number(ish_w))
            }
            if(Lp!=0){
                return this.decompose(delta, Lp)
            }
            return false
            
        }
        catch{
            return false
        }
    }

    static decompose(delta:string, dbA:number):Array<Number>|boolean{
        interface tab{
            [key: string]: Array<number>;
            'd-1':Array<number>
            'd0':Array<number>
            'd1':Array<number>
            'd2':Array<number>
            'd3':Array<number>
            'd4':Array<number>
            'd5':Array<number>
            'd6':Array<number>
            'd7':Array<number>
            'd8':Array<number>
            'd9':Array<number>
            'd10':Array<number>
            'd13':Array<number>
            'd16':Array<number>


        }
        const tab1:tab =
            {
             'd-1' :[-26.3, -24.9, -21.9, -18.6, -12, -3, -7, -15.8],
            'd0' :[-18.7, -16.5, -13.8, -9.5, -6.5, -5.2, -7, -11.4],
            'd1' :[-14.2, -11.3, -8.4, -6, -4.4, -6.1, -9, -14.4],
            'd2' :[-9.4, -7.7, -6.1, -4.7, -4.1, -6.8, -10.6, -14.4],
            'd3' :[-6.7, -5.3, -4, -3.7, -4.1, -7.4, -11.6, -16.1],
            'd4' :[-4.1, -3.3, -2.6, -2.8, -4.3, -7.9, -12.4, -17.1],
            'd5' :[-3, -1.4, -0.9, -2.4, -4.7, -8.5, -13.7, -19],
            'd6' :[-1.2, 0.2, 0.2, -2, -4.8, -9.7, -14.8, -19.8],
            'd7' :[0.2, 2.2, 0.7, -2.1, -5.3, -9.9, -14.4, -18.5],
            'd8' :[1.2, 3.8, 1.7, -1.8, -5.6, -11.1, -17, -24.1],
            'd9' :[4.2, 4.3, 2.2, -2, -5.7, -11.1, -16.8, -22.8],
            'd10' :[7.1, 5.5, 1.8, -2.4, -6.4, -10.6, -14, -17.1],
            'd13' :[9.9, 9, 2.5, -3, -7.3, -11.6, -16.4, -20.7],
            'd16' :[14, 11.1, 2.3, -3.9, -9.3, -13.5, -18, -22.5]}
        const key:string = 'd'+String(delta)
        if(Array.isArray(tab1[key])){ 
            let arr = Array(8)

            for(let i=0;i<arr.length;i++){
                arr[i]=(dbA+tab1[key][i]).toFixed(2)
                if(arr[i]<0){arr[i]=0}
                

            }
            return arr
        }
        return false
        

    }
}
export default RuOsipov