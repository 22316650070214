import {Ra_nois_line,SourceNoisResponce} from "../models"
import React, {useState} from 'react';
import {observer} from "mobx-react-lite"
interface   nois_line_props {
    included:boolean // наличие в наборе
    nois_item: SourceNoisResponce
    click(selItem:SourceNoisResponce):any

}
export  default observer(function SearchResult(props:nois_line_props)
{  const[selected, setSelected] = useState<boolean>(false)


    function select_Check  (select:boolean):string{
        if(select){        
            return('border-slate-300')
        }else{return('border-slate-600')}
    }

    const thisNoisItem:SourceNoisResponce = props.nois_item
    
    const clicl_  = () => {
        setSelected((n)=>{return !selected})
        props.click(thisNoisItem)
    }

    
    function pub_to_log(pub:boolean){
    let p= {
        img:'stat_cloused.png',
        title:'общий доступ закрыт'
    }
    if(pub === false){
         p= {
            img:'stat_open.png',
            title:'общий доступ открыт'
        }
         return(p)
    }else{
         p= {
            img:'stat_cloused.png',
            title:'общий доступ закрыт'
        }
        return (p)
    }
}
    const pub = pub_to_log(props.nois_item.privat)
return(
    <div key={props.nois_item.id} onClick={clicl_} className={"m-2 border "+select_Check(props.included)+" p-2 rounded-md relative cursor-pointer hover:bg-neutral-900"}>
        <div>
            <h2 className="text-sm pr-3 leading-4 mb-2">{props.nois_item.name}</h2>
            <img src={pub.img} title={pub.title} className="w-5 absolute right-1 top-1" />
        </div>
        <p className="text-sm text-emerald-400">{props.nois_item.descript}</p>
       
        <div className="flex flex-row-reverse"><p className="text-sm text-indigo-500 hover:text-indigo-300">@{props.nois_item.loginown}</p></div>
    </div>
)
})