import {API_PARAMETERSUPLOADFORM, API_URL, API_SEARCHTEGS, API_ADDTEG, S3BUCKET, ADDNOISITEM } from "../config";
import {Formuploads3, AddNoisItem } from '../models'
import axios  from "axios";
import { Axios, AxiosError, AxiosResponse} from "axios";
import $api, { api, s3api } from "../api/auth";

class FormuploadService {


    static async getparameters(name:string, format:string, size:number, filesource:string): Promise<AxiosResponse<Formuploads3>>   {

       const response = await $api.post<Formuploads3>(API_PARAMETERSUPLOADFORM,{name, format, size, filesource})
        return  response
    }

    // static async search(tegname: string,limit:number=10): Promise<AxiosResponse<Array<Formuploads3>>>{
    //     let tegLC = tegname.toLowerCase();
    //     let tegArr = tegLC.replaceAll(' ','&w=')
    //     const response = await $api.get<Array<Formuploads3>>(API_SEARCHTEGS+'/'+ String(limit)+'?w='+tegArr)
    //     return  response
    // }

    static async addNoisItem(Form: AddNoisItem): Promise<AxiosResponse>{
        const response = await $api.post(ADDNOISITEM, Form)
        return  response
    }

    static async sendFormtoS3(formData:FormData):Promise<AxiosResponse> {
                    // const config = {
                    // headers: {
                    //     "Access-Control-Allow-Origin": "*",
                    //     'Access-Control-Request-Method':'POST',
                    //     "enctype":"multipart/form-data",
                    //     'Origin':'*',
                    // },
                    //                             }
            const respons = await s3api.post(S3BUCKET, formData)
            return respons


    }
}
export default FormuploadService