
// require('dotenv').config(); 
import * as dotenv from "dotenv"
import React from 'react';
import Conteiner from "./components/Conteiner"
import {observer} from "mobx-react-lite"


function App() {
  return (
    <Conteiner />
  );
}

export default observer(App);
