import {observer} from "mobx-react-lite"
import React from 'react';
interface PaginationProps{
    clickPagination():any
}
export  default observer(function Pagination(props:PaginationProps){
    const class_butt = 'w-full place-self-center  border border-gray-700 relative inline-block   text-teal-800 font-medium text-xs leading-tight uppercase rounded shadow-md hover:text-teal-600 hover:bg-stone-800 hover:shadow-lg focus:bg-stone-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-stone-800 active:shadow-lg transition duration050 ease-in-out max-h-30 '
    const class_butt2 = 'w-full text-center' 
    return(<div className="px-2">
                <button 
                onClick={()=>props.clickPagination()}   
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className={class_butt} >
                <div className="grid grid-cols-1 gap-1 ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 block place-self-center">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
            </svg>
        </div>
                
            </button>
        

            
            
    </div>

)
})