import React from 'react';
import {Ra_teg} from '../models'

interface   Teg_props {
    tegs: Ra_teg
    onClick_(addTag:Ra_teg):any
    addclass?:string
    pref:string
}

export function Item_teg(props: Teg_props) {
    let add_Class = 'border border-zinc-500 hover:bg-zinc-600 '
    if(props.addclass){ add_Class=props.addclass}
    const classTeg = 'flex-1 text-center '+
        'bg-zinc-700  my-1 mx-1 py-1 px-2  '+
        'rounded-lg object-contain cursor-pointer '+
        add_Class
    const teg :Ra_teg = 
     {
        _id: props.tegs._id,
        name: props.tegs.name,
        clicks: props.tegs.clicks,

    }
    const clickTeg = () => {
        props.onClick_(teg)
    }
    return(
        <div key={'"'+props.pref+props.tegs._id+'"'} className={classTeg}
        onClick={clickTeg} >
            {props.tegs.name}
        </div>
    )

}