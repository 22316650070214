import React, {useEffect, useState} from 'react';
import FormuploadService from '../services/FormuploadService'
import { FileChose} from '../models'
import {observer} from "mobx-react-lite"
interface   form_props {
    fileCH:FileChose

}

function Form_uploadPasport(props:form_props){
    const [input_upload_pasp, setInput_upload_pasp] = useState(props.fileCH)  // форма загрузки паспорта

    useEffect(() => {
        
   }, [])
    return(
        <div  className='text-xs text-gray-200 flex flex-row place-content-between '>
            <div className=''>
                <p className='truncate hover:text-clip'>{input_upload_pasp.filename + ' ' + input_upload_pasp.formatfilesize + ' ' + input_upload_pasp.status_upload }</p>
            </div>
        {input_upload_pasp.status_upload == 4 && <div className='text-emerald-300 text-xs'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-3 h-3">
<path strokeLinecap="round" d="M4.5 12.75l6 6 9-13.5" />
</svg></div>
        }

        </div>
    )
}
export default observer(Form_uploadPasport);