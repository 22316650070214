import React ,{useEffect, useState} from "react"
import {Ra_teg, StatisticsInt} from '../models'
import {SourceNoisService} from '../services/SourceNoisService'
export function StatisticsComponent() {
    const [counnoisit, setCountnoisit] = useState<Number>()
    const [countRecord, setcountRecord] = useState<Number>()
    async function getStat(){
        const response = await SourceNoisService.getStatistics()
        
        if(response.status == 200){ 
            if(response.data){
                console.log('response.data')
                console.log(response.data)
                setCountnoisit(response.data[0].countnoiseitem)
                setcountRecord(response.data[0].countnoiserecord)
                
                console.log(countRecord)
            }
    }}

    useEffect(() => {
    getStat()
      
   }, [])

    return(
        <></>
        // <div  className=''>
        //     количество шумовых характеристик: {String(countRecord)}
        // </div>
    )
}