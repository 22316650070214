// Компонент добавить запись
import { clickOptions } from '@testing-library/user-event/dist/click';
import React, {AreaHTMLAttributes, HTMLInputTypeAttribute, useEffect, useState, useContext} from 'react';
import {Ra_nois_param} from '../models'
import {ErrorMessage} from "./ErrorMessage"
import {Tabl_params_nois} from './Tabl_params_nois'
import {Nois_param} from "./Nois_param_add"
import {search_teg_item} from "../data/serch_teg_add"
import {Item_teg} from "./Item_teg"
import {Ra_teg, AddNoisItem, Filetodb} from "../models"
import TegsService from '../services/TegsService';
import { AxiosError } from 'axios';
import RaUtilita from '../services/RaUtilita';
import RuOsipov from '../services/RuOsipov'
import Forms_uploadPasports from './uploadpasports'
import {Context} from "../index"
import {S3BUCKET,S3API_URL, LOC } from "../config";
import MessageADDNoisitem  from '../message'
import FormuploadService from '../services/FormuploadService';
import {observer} from "mobx-react-lite"

const M = new MessageADDNoisitem()
const Text = M.getLang('ru')
interface callBackPromiss{
    reload():any
    selectMenuMyIsh(nMenu:number): any
}
interface   Nois_tab_param_props {

    nois_param: Ra_nois_param
}

export default observer(function Add_nois_item(props:callBackPromiss){
    const it_nois_param: Ra_nois_param = {
        n: 0,
        actav:{
            A63: 0,
            A125: 0,
            A250: 0,
            A500: 0,
            A1000: 0,
            A2000: 0,
            A4000: 0,
            A8000: 0,
            tot: 0,
            },
        type: '',
        descript: '',
        distans: 0

    }
    function clearParamNois(): Ra_nois_param {
        return (
            {
                n:0,
                actav:{
                    A63: 0,
                    A125: 0,
                    A250: 0,
                    A500: 0,
                    A1000: 0,
                    A2000: 0,
                    A4000: 0,
                    A8000: 0,
                    tot: 0,
                    },
                type: '',
                descript: '',
                distans: 0
        
            }
        )
    }
   // const [new_parm_nois, setNew_parm_nois] = useState<Ra_nois_param>(it_nois_param) 
   const {store} = useContext(Context);

    const [nameState, setNameState] = useState(String)
    const [descriptionState, setDescriptionState] = useState(String)
    const [privatStat, setPrivatState] = useState(false)
    const [input_parm_nois, setInput_parm_nois] = useState('')
    const [error, setError]  = useState(String)
    const [box_nois_param, setBox_nois_param]  = useState(Array<Ra_nois_param>) // параметры добавленные на сохранение
    const [arrayNew_nois_param, setArrayNew_nois_param]  = useState(Array<Ra_nois_param>) // параметры шума введенные, но не сохранненные 
    const [addSuccessfully, setAddSuccessfully] = useState<boolean>(false)


    async function addSuccessfullyAction(){
        props.selectMenuMyIsh(2)
        props.reload()
        store.clearFileuplloadArr()
        setNameState('')
        setDescriptionState('')
        setBox_nois_param([])
        setInput_parm_nois('')
        setAddSuccessfully(true)
        setArrayNew_nois_param([])
        setEmptyNteg(-1)
        setTegs_for_save([])
        setTimeout(function cb1() {
            setAddSuccessfully(false)
          }, 5000)
    
        
    }
    
    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault()
    }

    function convertToParametrNois(ch:string):Ra_nois_param
    {
        const ra_nois_param:Ra_nois_param = {
            n: 0,
            actav:{
                A63: 0,
                A125: 0,
                A250: 0,
                A500: 0,
                A1000: 0,
                A2000: 0,
                A4000: 0,
                A8000: 0,
                tot: 0,
                },
            type: '',
            descript: '',
            distans: 0
    
            }

        
        if (ch.trim().length === 0)
        {
            //setNew_parm_nois(clearParamNois())
            setError('неправильно заполнено поле "Параметры"')
            return(ra_nois_param)
        }
        else{
            let whitespace = 1
            let dist = 0
            let p = false
            if(ch[0] === 'p'){
                p = true
                if(p){
                    if(ch.length>2){
                        if(Number.isInteger(Number(ch[1].replace(' ','-')))){
                            whitespace = 2
                            
                            dist = Number(ch[1])
                            console.log('dist1')
                            console.log(dist)
                            if(Number.isInteger(Number(ch[2].replace(' ','-')))){
                            
                                dist = dist *10 + Number(ch[2])
                                console.log('dist')
                                console.log(dist)
                                whitespace = 3 
                            }
                            if(ch[2] === '.' || ch[2] === ','){
                                if(ch.length>3){
                                    if(Number.isInteger(Number(ch[3].replace(' ','-')))){
                                        dist = dist + Number(ch[3])*0.1 
                                        console.log('dist')
                                        console.log(dist)
                                        whitespace = 4 
                                    }
                                }


                            }
                        }
                        else
                        {
                            setError('После "p" укажите расстояние от точки замера до ИШ в метрах')
                        }
                    }
                }
            }
            console.log(whitespace)
            if(ch[0] === 'p' || ch[0] === 'w'){
                if(ch[whitespace] === ' '){
                    if(ch.length > 3){
                        
                        let param_arr = []
                        let param_item = ''
                        let probel = whitespace
                        let coment:string

                        for (var i=whitespace+1; i < ch.length; i++){
                            if(ch[i] != ' ' && i != ch.length-1){
                                param_item += ch[i]
                                probel = 0
                            }
                            else{
                                if (i === ch.length-1){
                                    param_item += ch[i]
                                    probel = 0
                                }
                                if(probel == 0){
                                    param_arr.push(param_item)
                                    param_item = ''
                                }
                                probel = 1
                            }
                        }
                        
                        // console.log(param_arr)
                        ra_nois_param['type'] = ch[0]
                        ra_nois_param.distans = dist

                        if(param_arr.length > 8){
                            ra_nois_param.descript =  param_arr.slice(9).join(' ')
                        }
                        let b = 0
                        for (var prop in ra_nois_param.actav) {
                            if(b < param_arr.length && b < 9){
                                let s =prop as string
                                ra_nois_param.actav[s] = Number(param_arr[b].replace(',', "."))
                                b++
                            }
                        }
                        
                        return(ra_nois_param)
                    }else{
                        //setNew_parm_nois(clearParamNois())
                        return(ra_nois_param)
                    }
                }
                else{
                    setError('Между параметрами должен быть пробел')
                    return(ra_nois_param)
                }
            }
            else{
                setError('Первый символ параметров должен быть "p" - звуковое давление или "w" - звуковая мощность')
                return(ra_nois_param)
            }
        }
    }
    
    function getRows(s:string,rowArray:string[]):Array<string>{
        
        const patterv1 = /\n/
        let indexTrim = s.search(patterv1)
        let row 
        if(indexTrim != -1){
            row = s.slice(0, indexTrim)
        }else{row = s}

        let nextS = s.slice((indexTrim+1))
        rowArray.push(row)
        // console.log('---',nextS)
        // console.log('---A',rowArray)
        if(indexTrim != -1){

            return(getRows(nextS, rowArray))
        }else{
            return(rowArray)
        }
    }
    const [clear_areal,setClear_areal] = useState<boolean>(false)
async function setInput_parmN(text:string) {
    await setInput_parm_nois(text)
}

    const param_Area_change = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setError('')
        let ch = event.currentTarget.value
        ch = ch.replaceAll('\t',' ')
        let rows=getRows(ch,[])
        console.log(ch)
        setInput_parm_nois(ch)
        // console.log(rows)
        let row_string = ''
        setArrayNew_nois_param([])
        console.log('----',rows)
        if(rows.length<=1 ){
            setClear_areal(false)
        }
 
        
        for(let i = 0; i<rows.length; i++){
            if(rows[i].startsWith('o->')){ // osipov start
                console.log('osipov:')
                let osipovParm = rows[i].slice(3).trim().split(';')
                console.log(rows[i].slice(2).trim().split(';'))
                if(osipovParm.length === 3){
                    console.log(osipovParm[0])
                    console.log(RaUtilita.checkNumber(osipovParm[0]))
                    if( RaUtilita.checkNumber(osipovParm[0])){
                        if( RaUtilita.checkNumber(osipovParm[1])){
                        let result:Array<Number>|boolean = RuOsipov.decompose(osipovParm[0],Number(osipovParm[1]))
                        console.log('osipov raschet:')
                        console.log(result)
                        if(typeof result != 'boolean'){
                            rows[i] = 'w ' + result.join(' ') +' '+ String(osipovParm[1]) +' '+String(osipovParm[2])
                            console.log(rows[i])

                        }
                        }
                    }
                    
                }
            }

            //PtoW osipov 16.16 p to w start
            if(rows[i].startsWith('PtoW->')){ // 
                console.log('osipov:')
                let osipovParm = rows[i].slice(6).trim().split(';')
                console.log(rows[i].slice(6).trim().split(';'))
                if(osipovParm.length === 7){
                    if( RaUtilita.checkNumber(osipovParm[0])){
                        if( RaUtilita.checkNumber(osipovParm[1])){
                            if( RaUtilita.checkNumber(osipovParm[2])){
                                // PtoW->2;2;7.5;2pi;10;Lэкв;
                                let l = osipovParm[0]
                                let w = osipovParm[1]
                                let dist = Number(osipovParm[2])
                                let L = Number(osipovParm[5])
                                let comment = osipovParm[6]
                                let amegaSTR = osipovParm[3]
                                let delta = osipovParm[4]

                                let lw:number = 0.0

                                let result:Array<Number>|boolean = RuOsipov.ptow(l, w, L, dist, amegaSTR, delta)
                                let result2:Array<Number>|boolean = RuOsipov.decompose(delta,Number(L))
                                if(typeof result != 'boolean' && typeof result2 != 'boolean'){
                                    rows[i] = 'w ' + result.join(' ') +' '+ String(Math.round(Number(RuOsipov.assembling(result)))) +' '+ 'Перевод УЗД в УЗМ' + '\n'+
                                              'p' + String(dist) +' '+ result2.join(' ') +' '+ String(Math.round(Number(RuOsipov.assembling(result2)))) +' '+ 'УЗД на расстоянии '+String(dist)
                                    console.log(rows[i])
        
                                }



                            }
                        }
                    }


                }
            }


            console.log('00000 --- ', rows[i].length, '  -  ',rows[i][0])
            if(rows.length > 0 ){
                if(rows[i].length === 0){
                    // setInput_parmN(rows.join('\n'))
                    await setInput_parm_nois(rows.join('\n'))
                    break
                    
                }
            }
            if (rows[i].length===1){
                 
                switch(rows[i][0]) {

                    case 'ц':  // if (x === 'value1')
                    case 'w':  // if (x === 'value1')
                        rows[i]='w'
                        await setInput_parm_nois(rows.join('\n'))
                        continue;
                
                    case 'з':  // if (x === 'value2')
                    case 'p':
                        rows[i]='p'
                        await setInput_parm_nois(rows.join('\n'))
                        break;

                    case 'щ':  // if (x === 'value2')
                    case 'o':
                    case 'O':
                    case 'Щ':
                        if(clear_areal === false){
                            rows[i]='o->10;Lэкв;Коментарий'
                            setInput_parm_nois(rows.join('\n'))
                        }
                    break;

                    case 'З':  // if (x === 'value2')  формула 16,16 Осипов
                    case 'P':
                        if(clear_areal === false){
                            rows[i]='PtoW->2;2;7.5;2pi;10;Lэкв;1 и 2-й параметр - длинна и ширина ИШ, 3-й - растояние от точки замера до ИШ, 4-й - телесный угол,5-й - дельта для разложения на октавы 6-й - УЗД'
                            // Омега - пространственный угол (в стерадианах), в который излучается шум; 
                            //для источника шума в пространство Омега = 4пи; 
                            //на поверхности территории или ограждающих конструкций зданий и сооружений Омега = 2пи;
                            // в двухгранном углу, образованном ограждающими конструкциями зданий и сооружений Омега = пи; 
                            //в трехгранном углу Омега = пи/2.
                            setInput_parm_nois(rows.join('\n'))
                        }
                    break;


                    // case 'o':  // if (x === 'value2')
                    // if(clear_areal === false){
                    //     rows[i]='o('
                    //     setInput_parm_nois(rows.join('\n'))
                    // }
                    
                    // break;
                
                    default:
                        // setInput_parm_nois('w');
                    break
              }}
            let it:Ra_nois_param = await convertToParametrNois(rows[i])
                it.n = box_nois_param.length + i
                await setArrayNew_nois_param(current => [it, ...current ]) 
            if(rows[i].length<1 ){
                setClear_areal(false)
            }
            if(rows[i].length>=1 ){
                setClear_areal(true)
            }
        }

       
    }

    
    const param_butt_add_click = (event:React.MouseEvent) =>{
        let boolAdd = false

        arrayNew_nois_param.map((item) => {

            if( !isNaN(item.actav.A63)  && !isNaN(item.actav.A125)  
                && !isNaN(item.actav.A125)
                && !isNaN(item.actav.A250)
                && !isNaN(item.actav.A500)
                && !isNaN(item.actav.A1000)
                && !isNaN(item.actav.A2000)
                && !isNaN(item.actav.A4000)
                && !isNaN(item.actav.A8000)
                && !isNaN(item.actav.tot)            
            ){
                if(item.type !='' && item.actav.tot !=0){
                    setBox_nois_param(current => [item, ...current ])
                    boolAdd = true
                }
            }
           
        }
            )  
        if(boolAdd){
            setInput_parm_nois('')
            //setNew_parm_nois(clearParamNois())
            setClear_areal(false)
            setArrayNew_nois_param([clearParamNois()])
        }

    }

    //tegAdd

    const [tegs_for_save, setTegs_for_save] = useState(Array<Ra_teg>)   
    const [tegs_Found, setTegs_Found] = useState(Array<Ra_teg>)
    const [loadTeg, setLoadTeg] = useState<boolean>(false)  //состаяние загрузка тегов
    const [errorloadTeg, setErrorloadTeg] = useState<boolean>(false)  //состаяние ошибка загрузки тегов
    const [emptyFoundTeg, setEmptyFoundTeg] = useState<boolean>(false)  //состаяние не найдено тегов по запросу
    const [emptyNteg, setEmptyNteg] = useState<number>(-1)  //сщетчик не найденных тегов
    async function getPopularTegs(){
        try{
            setEmptyFoundTeg(false)
            setErrorloadTeg(false)
            setLoadTeg(true)

            const response = await TegsService.getPopularTegs(6,0)
            await setTegs_Found(response.data)
            setLoadTeg(false)
        }
        catch{
            setLoadTeg(false)
            setErrorloadTeg(true)
        }

    }
    useEffect(() => {
         getPopularTegs()
        
    }, [])

    const add_teg_click = async(addTag:Ra_teg) => {    // клик по тэгу
        let true_add  = true
        for(let i = 0;i<tegs_for_save.length;i++){
            if(tegs_for_save[i]._id === addTag._id){true_add=false}
        }
        if(true_add){
            await setTegs_for_save(current => [...current, addTag])

        }
        
    }

    const [tegSearch, setTegSearch] = useState<string>('')
    const [newteg, setNewteg] = useState(Array<Ra_teg>) 
    const [newtegSelected, setNewtegSelected] = useState(Array<Ra_teg>) 
    async function searchTeg(teg:string) {
        try{
             setEmptyFoundTeg(false)
             setErrorloadTeg(false)
             setEmptyFoundTeg(false)
             if(teg.length === 0){
                getPopularTegs()
             }
            if(teg.length >2){
                 setLoadTeg(true)
                const tegAllowed = RaUtilita.charAllowed(teg.toLowerCase())
                const response = await TegsService.search(tegAllowed)
                 setTegs_Found(response.data)
                 setLoadTeg(false)
                if(response.data.length == 0){
                    setEmptyFoundTeg(true)
                    let newteg_:Ra_teg = {
                        _id: String(emptyNteg),
                        clicks:0,
                        name: tegAllowed
                    }
                    
                    let free = true // имя тега не занято
                    for (let i = 0; i<tegs_for_save.length; i++){

                        if (tegs_for_save[i].name == tegAllowed){
                            free = false
                            setEmptyFoundTeg(false)
                            
                        }
                    }
                    if(free){
                        setEmptyNteg(emptyNteg-1)
                        setNewteg([newteg_])
                    }
                    
                }
                else{
                     setEmptyFoundTeg(false)
                }

            }
            else{
                if(tegs_Found.length === 0){
                     getPopularTegs()
                }
            }
      }
      catch(e:unknown){
        const error = e as AxiosError
        await setLoadTeg(false)
        await setErrorloadTeg(true)
        }
    }

    const rm_teg_click = async(addTag:Ra_teg) => {    // клик по тэгу
        setTegs_for_save(tegs_for_save.filter(p => p._id !== addTag._id));
    }

    //tegAddEnd

    //Start Send Add NoisSource
    function addbutton(){

        let tegsOld:Array<Ra_teg>=[]
        let tegsNew:Array<Ra_teg>=[]
        let allowedNext = true
        let message:string | undefined



        if(box_nois_param.length == 0){
            allowedNext=false
            message = Text.PARAMETERSNOISZERO
        }

        if(nameState.replace(' ','').length < 4){
            allowedNext=false
            message = Text.NAMEERR
        }
        if(nameState.length > 255){
            allowedNext=false
            message = Text.FILELISTZERO
        }
        if(store.filesuploaded.length == 0){
            allowedNext=false
            message = Text.FILELISTZERO
        }

        if(tegs_for_save.length == 0){
            allowedNext = false
            message = Text.TEGLISTZERO
        }else{
            for(var i=0; i<tegs_for_save.length;i++){
                if(tegs_for_save[i]._id[0] === '-'){
                    tegsNew.push(tegs_for_save[i])
                }else{
                    tegsOld.push(tegs_for_save[i])
                }
                
            }

        }
        const item:AddNoisItem = {
            name:nameState,
            descript:descriptionState,
            tegsnew:tegsNew,// набор тегов
            tegs:tegsOld,// набор тегов
            keysfiles:[], 
            privat:privatStat,
            params:box_nois_param
        }
        for(var i=0; i < store.filesuploaded.length; i++){
            if(store.filesuploaded[i].status_upload == 0){
                allowedNext=false
                message = Text.FILENOTUPLODED
            }
            if(store.filesuploaded[i].status_upload == 4)
            item.keysfiles.push(
            {
                key:store.filesuploaded[i].key,
                filename:store.filesuploaded[i].filename,
                filesize:store.filesuploaded[i].filesize,
                filseformat:store.filesuploaded[i].fileformat,
                urlbucket:S3API_URL+S3BUCKET
            } )
        }
        console.log(message)
        console.log(item)
        if(allowedNext){
            setError('')
            console.log('send')
            let result = FormuploadService.addNoisItem(item)
            
            addSuccessfullyAction()
        }else{
            if(message){
                setError(message)
            }
            
        }
        
    }

    function nameChange(val:string){
        if(val.length < 100){
            setNameState(RaUtilita.charAllowedFileName(val, true))
        }
        
    }

    return(<>{addSuccessfully && <div className='relative box-content text-center text-emerald-500'><h1 className=' text-3xl '>СПАСИБО!</h1>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className=" w-6 h-6 absolute top-0 right-0 ">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
        </svg>
    </div>}
    { !addSuccessfully && <div>
    
    
        <form key='addnoisitem' onSubmit={submitHandler}>
            <h1 className="mb-2">Добавить</h1>
            <input type="text"  
            onChange={e => nameChange(e.target.value)  }
            value={nameState}
            className='pl-5 text-blue-800 rounded-lg w-full mb-2' placeholder='Наименование' />
            <textarea 
            onChange={e => setDescriptionState(e.target.value)}

            className="w-full leading-tight text-zinc-800 rounded px-5" placeholder='Описание'></textarea>
            <div className="my-2">
            <div className="flex flex-row">
                <div className="grow"><input 
                onChange={e => searchTeg(e.target.value)}
                type="text"  className='pl-5 text-blue-800 rounded-lg w-full mb-2' placeholder='Ключевое слово' /></div>
   
            </div>
            <div className="flex flex-wrap mt-2 mb-2">
            { //выбранные теги
            tegs_for_save.map(teg_item => <Item_teg pref="tg5_" tegs={teg_item} key={teg_item._id} onClick_={rm_teg_click} addclass='text-violet-300 border border-violet-500 hover:bg-gray-600 '></Item_teg>  ) }
            
            </div>
            <div className="flex flex-wrap">
           
            { //найденные теги
            errorloadTeg && <div><p className='text-red-500'>ошибка</p></div>}
            {loadTeg && <div><img width={50} src="dwld_wait_please2.gif" alt="" /></div>}
            {emptyFoundTeg && <div><div><p className='text-teal-400'>Не найдено! Создать новый тег?</p></div><div>
            {newteg.map(teg_item => <Item_teg pref="tg5_" tegs={teg_item} key={teg_item._id} onClick_={add_teg_click} addclass='text-emerald-300 border border-emerald-500 hover:bg-gray-600 '></Item_teg>  ) }
                </div> </div>}
            
            {!loadTeg && !errorloadTeg && tegs_Found.map(teg_item => <Item_teg pref="tg4_" tegs={teg_item} key={teg_item._id} onClick_={add_teg_click}></Item_teg>  ) }
            </div>
            <div className="flex justify-center">
            <div className="mb-3 w-96">
                <Forms_uploadPasports />
               
            </div>
            
            </div>
            <div className='mb-4'> 
                <p className="form-label inline-block mb-2 text-gray-300">Видимость записи:</p>
                <div className="text-sm text-gray-400 flex flex-row">
                    <div className="basis0/2">
                        <input defaultChecked
                        onClick={e => setPrivatState(false)} type="radio" id="pub"
                        name="privat" value="false" className="mx-4"/>
                        <label>Публичная</label>
                    </div>
                    <div className="basis0/2">    
                        <input 
                        onClick={e => setPrivatState(true)}
                        type="radio" id="privat"
                        name="privat" value="true" className="mx-4"/>
                        <label>Приватная</label>
                    </div>

                </div>
            </div>
            <div>
            <textarea className="w-full leading-tight text-zinc-800 rounded px-5" placeholder='Параметры' value={input_parm_nois} onChange = {param_Area_change}></textarea>
            </div>
            <div className="flex flex-row">
                
                <div className="grow">
                <p className="text-zinc-400 leading-snug text-xs">Поле "Параметры" будет преобразовано в таблицу. Разделитель "пробел". <br/></p>
               
                 </div>
                <div className="grow-0 mb-2">
                    <button type="button" className="inline-block
                        px-6 py-2.5 bg-zinc-700
                        border border-zinc-500
                        leading-tight uppercase rounded
                        shadow-md hover:bg-zinc-600 hover:shadow-lg
                        focus:bg-stone-600 focus:shadow-lg focus:outline-none 
                        focus:ring-0 active:bg-zinc-800 active:shadow-lg 
                        transition duration050 ease-in-out ml-2" 
                        onClick = {param_butt_add_click}>+</button>
                </div>
            </div>
            {error && <ErrorMessage error={error}/>}
                {arrayNew_nois_param.map((item) => {

                    return(
                        <Tabl_params_nois key={String(item.n)+'Tabl_params_nois'} nois_param={item}></Tabl_params_nois>
                    )
                })}
                {box_nois_param.map((item) => {
                    return(
                        <Nois_param  key={String(item.n)} nois_param ={item} border_color='border-gray-600'/>
                    )
                })}
            </div>

            
            <div className="flex space-x-2 justify-center">
                <button
                onClick={e => addbutton()}
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration050 ease-in-out"
                >Добавить</button>
            </div>
        </form> </div>}
        </>
    )
})