import React from 'react';
import {Ra_nois_param} from '../models'

interface   Nois_tab_param_props {
    nois_param: Ra_nois_param
    border_color: string
}
export function Nois_param(props:Nois_tab_param_props){
    const class_row = 'border '+ props.border_color +' overflow-hidden'
    const class_comment_row = 'border-b border-l border-r '+ props.border_color +' box-content px-1 text-xs text-center text-orange-200 ml-1 mr-1 mb-2'
    return(
        <div className='relative' key = {props.nois_param.n} >
        { props.nois_param.type && <div  className='absolute bottom-0 -left-5'>
            
            <p>L
            {props.nois_param.distans != 0 && <><sup className="font-features subs ">{props.nois_param.distans}</sup> 
            <sub className="font-features subs right-2 ">{props.nois_param.type}</sub></>}
            {props.nois_param.distans == 0 && <>
            <sub className="font-features subs  ">{props.nois_param.type}</sub></>}
            
                </p></div>}
            <div className="grid grid-cols-9 gap-1 box-content px-1 text-xs text-center text-orange-200">
                
                <div key = {String(props.nois_param.n) + '63'} className={class_row}>{props.nois_param.actav.A63}</div>
                <div  key = {String(props.nois_param.n) + '125'} className={class_row}>{props.nois_param.actav.A125}</div>
                <div key = {String(props.nois_param.n) + '250'} className={class_row}>{props.nois_param.actav.A250}</div>
                <div key = {String(props.nois_param.n) + '500'} className={class_row}>{props.nois_param.actav.A500}</div>
                <div key = {String(props.nois_param.n) + '1000'} className={class_row}>{props.nois_param.actav.A1000}</div>
                <div key = {String(props.nois_param.n) + '2000'} className={class_row}>{props.nois_param.actav.A2000}</div>
                <div key = {String(props.nois_param.n) + '4000'} className={class_row}>{props.nois_param.actav.A4000}</div>
                <div key = {String(props.nois_param.n) + '8000'} className={class_row}>{props.nois_param.actav.A8000}</div>
                <div key = {String(props.nois_param.n) + 'tot'} className={class_row}>{props.nois_param.actav.tot}</div>
            </div>
            <div className={class_comment_row}><p>{props.nois_param.descript}</p></div>
        </div>
    )

}