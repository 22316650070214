class RaUtilita {

    static  checkNumber(x:string|number|undefined) {

        // return type: false - is not Number; 1 - integer; 2 - float
        console.log('--===')
        console.log(typeof Number(x)) 
        if(typeof  Number(x) == 'number' ){
        
            // check if it is integer
            if (Number.isInteger(x)) {
                // console.log(`${x} is integer.`);
                return 1
            }
            else {
                return 2
                console.log(`${x} is a float value.`);
            }
        
        } else {
            return false
            // console.log(`${x} is not a number`);
        }
    }


    static  charAllowed(text:string): string  {
        const charAllowed:{[id:string]:number} = {
                            'z':1, 'y':1, 'x':1, 'w':1, 'v':1, 'u':1, 't':1, 's':1, 'r':1, 'q':1, 'p':1, 'o':1, 'n':1, 
                            'm':1, 'l':1, 'k':1, 'j':1, 'i':1, 'h':1, 'g':1, 'f':1, 'e':1, 'd':1, 'c':1, 'b':1, 'a':1, 'а':1, 'б':1, 'в':1, 'г':1, 
                            'д':1, 'е':1, 'ё':1, 'ж':1, 'з':1, 'и':1, 'й':1, 'к':1, 'л':1, 'м':1, 'н':1, 'о':1, 'п':1, 'р':1, 'с':1, 'т':1, 'у':1, 
                            'ф':1, 'х':1, 'ц':1, 'ч':1, 'ш':1, 'щ':1, 'ъ':1, 'ы':1, 'ь':1, 'э':1, 'ю':1, 'я':1}
        let allowText = ''
        for(let i = 0; i < text.length; i++){
            
            if(charAllowed[String(text[i])]){
                allowText = allowText + text[i]
            }
        }                   
        return  allowText
    }

    static  charAllowedFileName(text:string, whitespaceAllowed:boolean = false): string  {
        let charAllowed:{[id:string]:number} = {
                            
                            '1':1, '2':1, '3':1, '4':1, '5':1, '6':1, '7':1, '8':1, '9':1, '0':1, '.':1,
                            'Z':1, 'Y':1, 'X':1, 'W':1, 'V':1, 'U':1, 'T':1, 'S':1, 'R':1, 'Q':1, 'P':1, 'O':1, 'N':1,
                            'z':1, 'y':1, 'x':1, 'w':1, 'v':1, 'u':1, 't':1, 's':1, 'r':1, 'q':1, 'p':1, 'o':1, 'n':1, 
                            'M':1, 'L':1, 'K':1, 'J':1, 'I':1, 'H':1, 'G':1, 'F':1, 'E':1, 'D':1, 'C':1, 'B':1, 'A':1, 'А':1, 'Б':1, 'В':1, 'Г':1, 
                            'm':1, 'l':1, 'k':1, 'j':1, 'i':1, 'h':1, 'g':1, 'f':1, 'e':1, 'd':1, 'c':1, 'b':1, 'a':1, 'а':1, 'б':1, 'в':1, 'г':1, 
                            'Д':1, 'Е':1, 'Ё':1, 'Ж':1, 'З':1, 'И':1, 'Й':1, 'К':1, 'Л':1, 'М':1, 'Н':1, 'О':1, 'П':1, 'Р':1, 'С':1, 'Т':1, 'У':1,
                            'д':1, 'е':1, 'ё':1, 'ж':1, 'з':1, 'и':1, 'й':1, 'к':1, 'л':1, 'м':1, 'н':1, 'о':1, 'п':1, 'р':1, 'с':1, 'т':1, 'у':1, 
                            'Ф':1, 'Х':1, 'Ц':1, 'Ч':1, 'Ш':1, 'Щ':1, 'Ъ':1, 'Ы':1, 'Ь':1, 'Э':1, 'Ю':1, 'Я':1, '-':1,
                            'ф':1, 'х':1, 'ц':1, 'ч':1, 'ш':1, 'щ':1, 'ъ':1, 'ы':1, 'ь':1, 'э':1, 'ю':1, 'я':1, '_':1}
        if(whitespaceAllowed){
            charAllowed[' ']=1
        }
        let allowText = ''
        for(let i = 0; i < text.length; i++){
            
            if(charAllowed[String(text[i])]){
                allowText = allowText + text[i]
            }
        }                   
        return  allowText
    }

    static formatFileAllowed(formattest:string, formatAllowed:Array<String>=['pdf', 'png', 'jpg', 'jpeg']):boolean{
        var allowed = false
        for(var i=0; i<formatAllowed.length;i++){
            if(formattest == formatAllowed[i]){
                allowed = true
                break
            }
        }
        return  allowed
    }

    static getFormatFile(filename:string):string|boolean{
        let format = ''
        for(var l=-1; l > -1*filename.length; l=l-1){
            if(filename.at(l) == '.'){                
                format = filename.slice(l+1)
                break
            }
        }

        if(format != ''){
            return format
        }
        else{
            return false
        }
    }

}
export default RaUtilita
