import {MyGrp,SourceNoisResponce} from "../models"
import React, {useState} from 'react';
import {observer} from "mobx-react-lite"
import MessageADDNoisitem from "../message"
import {SourceNoisService} from "../services/SourceNoisService"

interface   grp_props {
    group:MyGrp
    // nois_item: SourceNoisResponce
    click(selItem:MyGrp):any

}
export  default observer(function MyGroupComponent(props:grp_props)
{ 
    const message = new MessageADDNoisitem().getLang('ru')
    const[selected, setSelected] = useState<boolean>(false)
    const [linkState, setLinkState] = useState<boolean>(false) // процесс получения ссылки
    const [linkErrState, setLinkErrState] = useState<boolean>(false) // процесс получения ссылки
    const [messageState, setMessageState] = useState<string>(message.ERRORGETLINK) // процесс получения ссылки
    function select_Check  (select:boolean):string{
        if(select){        
            return('border-slate-300')
        }else{return('border-slate-600')}
    }

    const thisNoisgroup:MyGrp= props.group
    
    const clicl_  = () => {
        setSelected((n)=>{return !selected})
        props.click(thisNoisgroup)
    }

    
    function pub_to_log(pub:boolean){
        let p= {
            img:'stat_cloused.png',
            title:'общий доступ закрыт'
            }
        if(pub === false){
            p= {
                img:'stat_open.png',
                title:'общий доступ открыт'
            }
            return(p)
        }
        else{
            p= {
                img:'stat_cloused.png',
                title:'общий доступ закрыт'
            }
            return (p)
        }
    }
    async function messageErr(text:string){
        setLinkErrState(true)
        setMessageState(text)
        setTimeout(function cb1() {
            setMessageState('')
            setLinkErrState(false)
          }, 5000)

        
    }
    async function getGrpArchivLink(){
        setLinkState(true)
        setLinkErrState(false)
        try{
            const reponce = await SourceNoisService.getGrpArchivLink(props.group.id)
            console.log(reponce.status)
            if(reponce.status == 200){
                downloadFile(reponce.data.link)
                setLinkState(false)
            }
            else{
                messageErr(message.ERRORGETLINK)
            }
        }
        catch(e:any){
            setLinkState(false)
            messageErr(message.ERRORCONNECTGETLINK)
        }

        
    }

    function downloadFile(link:string) {
        console.log(link)
        window.location.href = link; 
    }

    const pub = pub_to_log(props.group.privat)

return(
    <div key={props.group.id}  className={"m-2 border border-slate-700 p-2 rounded-md relative hover:border-slate-500"}>
        <div>
            <h2 className="text-lg pr-3 leading-4 mb-5">{props.group.nameGrp}</h2>
            <p className="absolute right-14 top-1 text-slate-400 text-sm ms:hidden">{message.GROUPTITLE}</p>
            <img src={pub.img} title={pub.title} className="z-40 w-5 absolute right-7 top-1" />
            {linkState && <span className="z-50 absolute right-1 top-1 mx-2 px-2 text-sm text-violet-300 rounded-lg border border-violet-300 bg-violet-700 ">{message.STARTGETINGLINK}</span>}
            {linkErrState && <span className="z-50 absolute right-1 top-1 mx-2 px-2 text-sm text-red-600 rounded-lg border border-red-400 bg-red-200 ">{messageState}</span>}
            <svg className="z-40 absolute right-1 -top-2 text-red-400 h-5 w-5 mt-2 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" clipRule="evenodd" />
            </svg>
        </div>
        <p className="text-sm text-emerald-400 cursor-pointer hover:text-red-300" 
            onClick={getGrpArchivLink}
            >{message.GETLINKDOWNLOAD} </p>
        <p className="text-sm text-emerald-400 cursor-pointer hover:text-red-300"
        onClick={clicl_}>
            {message.VIEWGROUP}</p>
       
        <div className="flex flex-row-reverse"><p className="text-sm text-indigo-500 hover:text-indigo-300">@{props.group.loginown}</p></div>
    </div>
)
})