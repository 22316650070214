import { GETSTATISTICSURL, FINDBYTEG, ADDGROUP, GETMYGROUP, GETGROUPARCHIVE, GETGROUPNOIS, GETISHARCHIVE,  GETMYISH, SEARCHBYTEXT, FIND} from "../config";
import {Formuploads3, AddNoisItem , SourceNoisResponce, GrpAdd, MyGrp, LinkArchive, StatisticsInt} from '../models'
import axios  from "axios";
import { Axios, AxiosError, AxiosResponse} from "axios";
import $api, { api } from "../api/auth";
import RaUtilita from '../services/RaUtilita';

export class SourceNoisService {


    static async findBytegs(tegs:Array<string>): Promise<AxiosResponse<Array<SourceNoisResponce>>>   {
        let query = '/10/0/tegsArr?'
        for(let i = 0; i<tegs.length;i++){
        query += 'tegsArr=' + tegs[i]
        if(i != tegs.length){query += '&'}
        }
       const response = await $api.get <Array<SourceNoisResponce>>(FINDBYTEG+query)
        return  response
    }


    static async find(tegs:Array<string>,text:string): Promise<AxiosResponse<Array<SourceNoisResponce>>>   {
        console.log('text')
        console.log(text)
        if(text===''){
            return await this.findBytegs(tegs)
        }
        let newText = RaUtilita.charAllowedFileName(text)
        newText = newText.replace(' ','+')

        let query = '/10/0/'+text+'/tegsArr?'
        for(let i = 0; i<tegs.length;i++){
        query += 'tegsArr=' + tegs[i]

        if(i != tegs.length){query += '&'}
        }
       const response = await $api.get <Array<SourceNoisResponce>>(FIND+query)
        return  response
    }

    static async group(data:GrpAdd): Promise<AxiosResponse<string>>   { //создает группу и формирует архив
        console.log('grouping start')

       const response = await $api.post <string>(ADDGROUP,data)
        return  response
    }

    static async getMyGroup( limit:number=10, skip:number=0): Promise<AxiosResponse <Array<MyGrp>>>   { //создает группу и формирует архив
        console.log('grouping start')

       const response = await $api.get <Array<MyGrp>>(GETMYGROUP + '/' + limit + '/' + skip)
        return  response
    }
static async getMyIsh(limit:number = 10, skip:number = 0):Promise<AxiosResponse<Array<SourceNoisResponce>>>{
    let query = '/'+limit+'/'+skip+''
    
   const response = await $api.get <Array<SourceNoisResponce>>(GETMYISH+query)
    return  response
}

    static async getGrpArchivLink(idGrp:string):Promise<AxiosResponse <LinkArchive>>{
        const response = await $api.get <LinkArchive>(GETGROUPARCHIVE +  '/'+ idGrp)
        return  response
    }

    static async getIsFromGRP(idGrp:string):Promise<AxiosResponse<Array<SourceNoisResponce>>>{
        const response = await $api.get <Array<SourceNoisResponce>>(GETGROUPNOIS +  '/'+ idGrp)
        return  response
    }

    static async getISHArchivLink(idIsh:string):Promise<AxiosResponse <LinkArchive>>{
        const response = await $api.get <LinkArchive>(GETISHARCHIVE +  '/'+ idIsh)
        return  response
    }

    static async searchByText(text:string):Promise<AxiosResponse <Array<SourceNoisResponce>>>{
        
        let newText = RaUtilita.charAllowedFileName(text)
        newText = newText.replace(' ','+')
        const response = await $api.get <Array<SourceNoisResponce>>(SEARCHBYTEXT +  '/'+ newText)
        
        return  response
    }

    static async getStatistics():Promise<AxiosResponse <Array<StatisticsInt>>>{
        
        const response = await api.get <Array<StatisticsInt>>(GETSTATISTICSURL)
        
        return  response
    }
}