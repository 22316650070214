// require('dotenv').config(); 
// require('dotenv').load();
// import env from "react-dotenv";
export const URL_AUTH: string = '/auth/'
export const LOC = 'ru'
export const URL_LOGOUT: string = '/auth/logout'
export const URL_REGISTRATION: string = '/users/registration'
export const URL_REFRESHTOKEN = '/auth/update'
// export const API_URL = "http://127.0.01:8000"
// console.log('-------------',process.env.API_URL)
export const API_URL = 'http://79.174.13.135:8000'
export const S3API_URL = 'https://storage.yandexcloud.net'
export const S3BUCKET = '/rumidafiles1'
export const API_GETALLTEG = '/tag/gettegs' // /{limit}/{skip}
export const API_SEARCHTEGS = '/tag/searchtegs' // /{limit}
export const API_ADDTEG = '/tag/add/teg'
export const API_CLICKUPTEG = '/tag/clickup'
export const API_PARAMETERSUPLOADFORM = '/file/parameters/pasports'
export const MAXSIZEUPLOADPASPORT = 2097152//bt
export const ADDNOISITEM = '/nois/add'
export const FINDBYTEG = '/nois/findbyteg' // поиск по  тегам
export const FIND = '/nois/find' // поиск 
export const ADDGROUP = '/nois/addgroup' // добавить группу источников
export const GETMYGROUP = '/nois/getmygrps'
export const GETMYISH = '/nois/getmyish'
export const GETGROUPARCHIVE = '/nois/getgrparchiv'
export const GETISHARCHIVE = '/nois/getisharchiv'
export const GETGROUPNOIS = '/nois/getishgrp' // поллучить ИШ группы
export const SEARCHBYTEXT = '/nois/bytext' 
export const GETSTATISTICSURL = '/nois/getstatistics' // получить статистику
