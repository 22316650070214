import {Ra_teg, SourceNoisResponce} from "../models"
import React, {useState} from 'react';
import {Item_teg} from './Item_teg'
import {Tabl_params_nois} from './Tab_param_nois_info'
import MessageADDNoisitem from "../message"
import { stringify } from "querystring";
import { SourceNoisService } from "../services/SourceNoisService";
interface   nois_line_props {
    roll:number
    SelectedNoisItem: SourceNoisResponce
    deleteClick(idnoisSource:string):any
}
export function SelectedNoisItem(props:nois_line_props){
    const message = new MessageADDNoisitem().getLang('ru')
    const [linkState, setLinkState] = useState<boolean>(false) // процесс получения ссылки
    const [linkErrState, setLinkErrState] = useState<boolean>(false) // процесс получения ссылки
    const [messageState, setMessageState] = useState<string>(message.ERRORGETLINK) // процесс получения ссылки

    function rollDetect(n:number):boolean{
        if(n===1){
            console.log('---',n)
            return false
        }
        return true
    }
    const [rollup, setRollup] = useState<boolean>(rollDetect(props.roll))  //состояние сверток
    const M = new MessageADDNoisitem()
    const Text = M.getLang('ru')
    function rollupSVG(bool:boolean):string{
        const rollupSVGFalse = 'M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25'
        const rollupSVGTrue = 'M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12'
        if(bool){return rollupSVGTrue}
        return rollupSVGFalse
    }


    function pub_to_log(pub:boolean){
        let p= {
            img:'stat_cloused.png',
            title:Text.PRIVATTRUE
        }
        if(pub===false){
             p= {
                img:'stat_open.png',
                title:Text.PRIVATFALSE
            }
             return(p)
        }else{
            return(p)
        }
        }
    
    const pub = pub_to_log(props.SelectedNoisItem.privat)

function StringtoRa_Teg(teg:String):Ra_teg{
    const p:Ra_teg = {
        _id: '-1',
        name: teg.toString()

    }
    return p
}    
function deleteClick(){
    props.deleteClick(props.SelectedNoisItem.id)
}
function roll():string{
    if(rollup){
        return ''
    }
    return 'h-14'
}

function downloadFile(link:string) {
    window.location.href = link; 
}

async function messageErr(text:string){
    setLinkErrState(true)
    setMessageState(text)
    setTimeout(function cb1() {
        setMessageState('')
        setLinkErrState(false)
      }, 5000)

    
}

async function getIsharch() {
console.log('click')
    setLinkState(true)
    setLinkErrState(false)
    try{
        const reponce = await SourceNoisService.getISHArchivLink(props.SelectedNoisItem.id)
        console.log(reponce.status)
        if(reponce.status == 200){
            downloadFile(reponce.data.link)
            setLinkState(false)
        }
        else{
            messageErr(message.ERRORGETLINK)
        }
    }
    catch(e:any){
        setLinkState(false)
        messageErr(message.ERRORCONNECTGETLINK)
    }

}

return(
    <div className={"m-2 border border-slate-600 p-2 rounded-md relative pt-4 " + roll() +" overflow-hidden"} >
        <div>
             <button onClick={e => setRollup(!rollup)} title='свернуть'
                className="  text-red-500 hover:text-red-300 font-bold  rounded inline-flex items-center w-5 absolute right-13 top-1 title={pub.title} ">
                    
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d={rollupSVG(rollup)} />
            </svg>

            </button>

            <button onClick={e => deleteClick()} title='Убрать из набора'
                className="  text-red-500 hover:text-red-300 font-bold  rounded inline-flex items-center w-5 absolute right-1 top-1 title={pub.title} ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            </button>
            <img 
                src={pub.img} 
                title={pub.title} 
                className="w-5 absolute right-7 top-1" 

            />
            <h2 className="text-sm pr-3 leading-4 mb-3 mt-4">{props.SelectedNoisItem.name}</h2>

            
        </div>
        <p className="text-sm text-emerald-400">{props.SelectedNoisItem.descript}</p>
        <div>
         <Tabl_params_nois nois_param={props.SelectedNoisItem.params}  /> 
        </div>
        <div>
            <p className="text-base text-slate-500">Прикрепленные файлы:</p>
            <ul className="text-sm text-slate-500">
                {props.SelectedNoisItem.filename.map(file => <li>{file}</li>) }
            </ul>
        </div>
        <div>
            <p className="text-sm text-emerald-400 cursor-pointer hover:text-red-300" 
            onClick={() => getIsharch()}>Скачать</p>
        </div>
       <div className="flex flex-wrap mb-2 mt-4 max-w-lg">
        {props.SelectedNoisItem.tegs.map(teg => <Item_teg pref="tg3_" key={teg.toString()}  tegs={StringtoRa_Teg(teg)} addclass='text-gray-500 border border-gray-600 text-sm  ' onClick_={() => {}}/>)}
       </div>
       <div className="flex flex-row-reverse ">
            <p className=" text-sm text-indigo-500 hover:text-indigo-300 cursor-pointer max-w-fit">
                @{props.SelectedNoisItem.loginown}
            </p></div>
       
    </div>
)
}