import {IUser, Ilogin, AuthResponse, RefreshToken, Formuploads3} from '../models'
import {makeAutoObservable} from 'mobx'
import AuthService from '../services/AuthService';
import FormuploadService from '../services/FormuploadService'
import axios from 'axios';
import {API_URL, URL_REFRESHTOKEN, URL_LOGOUT} from '../config'
import React, {useState} from 'react';
import { FileChose, Ra_teg, RegistrationResponse} from '../models'
import {runInAction} from "mobx"
import { Axios, AxiosError, AxiosResponse } from "axios";

export default class Store {
    
    user = {} as IUser
    isAuth = false
    isMSGerr = ''
    isERRauth = false
    filesuploaded :Array<FileChose> = []
    loadtrue_ = false

    updateloadTrue(val:boolean){
        if(val){
            runInAction(() => {
                this.loadtrue_ = true;
              })
            
            
        }
        else{
            runInAction(() => {
                this.loadtrue_ = false;
              })
        }
        
    }

    changestatus(index:number,status:number){
        this.filesuploaded[index].status_upload = status
              
    }
    setFileupload(file:FileChose){
        this.filesuploaded.push(file)
    }

    clearFileuplloadArr(){
        this.filesuploaded = []
    }
    // :Formuploads3 = {
    //     key : '',
    //     X_Amz_Credential : '',
    //     acl : '',
    //     X_Amz_Algorithm : '',
    //     x_amz_meta_tag : '',
    //     bucket : '',
    //     content_type : '',
    //     X_Amz_Date : '',
    //     policy : '',
    //     X_Amz_Signature : '',
    // }
    
    getUser(){
        
        console.log('getUser')
       console.log(localStorage.getItem('user'))
    }

    constructor() {
        makeAutoObservable(this);
    }

    setMSGAuther(err:boolean, cod:number){
        this.isERRauth = err
        
        let text:string = 'Ошибка авторизации'
        switch(cod){
            case 401: text = 'Не верный email или пароль. Авторизация не прошла';
            break
            case 422: text = 'Введите логин и пароль';
            break
        }
        this.isMSGerr = text

    }


    // setFormParameters(parameters: Formuploads3){
    //     this.setInput_upload_pasp()
    //     this.uploadparameters = parameters
    // }


    setAuth(bool: boolean){
        this.isAuth = bool;

    }

    setUser(user:IUser){
        this.user = user;

    }



    async login(email: string, password: string){
        try {
            const ilog: Ilogin={
                email: email,
                password: password
            }
            console.log(password,'--',email)
            console.log(ilog)
            const response = await AuthService.login(ilog);
            console.log('ilog yes')
            console.log(response)
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('login', response.data.user.login);
            localStorage.setItem('id', response.data.user.id);
            
            this.setAuth(true);
            this.setUser(response.data.user)
            this.setMSGAuther(false,-1)
        }catch(e:any) {
            let cod:number = 422
            try{
                console.log(e.response?.status)
                cod = e.response?.status
            }catch{
                cod = 422
            }
            this.setAuth(false);
            this.setMSGAuther(true,cod)
        }

    }

    async registration(email: string, password1: string,password2: string, log:string){
        try {
            const response = await AuthService.registration(email, password1,password2, log);
            console.log(response)
            return response
            // localStorage.setItem('token', response.data.accessToken);
            // this.setzUser(response.data.user)
        }catch(e:any) {
            console.log(e.response?.data?.message)
        }

    }

    async logout(){
        try {
            const refreshtoken_: RefreshToken = {
                user_id: localStorage.getItem('id'),
                refreshtoken: localStorage.getItem('refresh_token')

            }
            const instance = axios.create({
                withCredentials: true,
                baseURL: API_URL
             })
            const response = await instance.post(URL_LOGOUT, refreshtoken_)
            // const response = await axios.post<AuthResponse>('/auth/1', refreshtoken_)
            // const response = await AuthService.logout();
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('access_token');
            localStorage.removeItem('id');
            this.setAuth(false);
            this.setUser({} as IUser)
            this.setMSGAuther(false,-1)
            
        }catch(e:any) {
            console.log(e.response?.data?.message)
        }

    }

    async checkAuth(){
        try{
            const refreshtoken_: RefreshToken = {
                user_id: localStorage.getItem('id'),
                refreshtoken: localStorage.getItem('refresh_token')

            }
            const instance = axios.create({
                withCredentials: true,
                baseURL: API_URL
             })
            const response = await instance.post(URL_REFRESHTOKEN, refreshtoken_)
            // const response = await axios.post<AuthResponse>('/auth/1', refreshtoken_)
            console.log('ilog yes')
            console.log(response)
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('login', response.data.user.login);
            localStorage.setItem('id', response.data.user.id);
            
            this.setAuth(true);
            this.setUser(response.data.user)
            this.setMSGAuther(false,-1)
        }
        catch(e:any){
            let cod:number = 422
            try{
                console.log('err')
                console.log(e.response?.status)
                cod = e.response?.status
            }catch{
                cod = 422
            }
            this.setAuth(false);
            this.setMSGAuther(true,cod)
          
        }
    }
}