import React from 'react';
import {Ra_nois_param} from '../models'

interface   Nois_tab_param_props {
    nois_param: Ra_nois_param
    td: string
}
export function TabParams_item_info (props:Nois_tab_param_props){
    
    //const class_comment_row = 'border-b border-l border-r '+ props.border_color +' box-content px-1 text-xs text-center text-orange-200 ml-1 mr-1 mb-2'
    return(

           
        <tr className='relative'>
             
        <td className={props.td}>{props.nois_param.actav.A63}</td>
        <td className={props.td}>{props.nois_param.actav.A125}</td>
        <td className={props.td}>{props.nois_param.actav.A250}</td>
        <td className={props.td}>{props.nois_param.actav.A500}</td>
        <td className={props.td}>{props.nois_param.actav.A1000}</td>
        <td className={props.td}>{props.nois_param.actav.A2000}</td>
        <td className={props.td}>{props.nois_param.actav.A4000}</td>
        <td className={props.td}>{props.nois_param.actav.A8000}</td>
        <td className={props.td}>{props.nois_param.actav.tot}</td>
        
        <td className={props.td}> { props.nois_param.type && <p className='text-amber-500'>L<sub className="font-features subs ">{props.nois_param.type}</sub></p>}
        </td>
        <td className={props.td} ><p className='text-emerald-400'>{props.nois_param.descript}</p></td>
        </tr>
  
    )
}