import React, {useContext, useState, useEffect} from "react"
import {observer} from "mobx-react-lite"
import RaUtilita from '../services/RaUtilita';
interface   Action {
    selectMenu(nMenu:number): any
    selected:number
    selectedGRPName:string

}
const screenWidth = window.screen.width
const screenHeight = window.screen.height

export default observer(function BarSearhResult(props:Action){
    const [select,setSelect] =useState<number>(props.selected)

    function choesclass_2(myN:number):string{
        
        const class_butt = 'border border-red-500 relative inline-block px-6  bg-stone-600 text-white  font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-stone-700 hover:shadow-lg focus:bg-stone-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-stone-800 active:shadow-lg transition duration050 ease-in-out max-h-30 mx-1'
        const class_butt_selected = 'border border-red-500 relative inline-block px-6  bg-stone-600 text-white  font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-stone-700 hover:shadow-lg focus:bg-stone-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-stone-800 active:shadow-lg transition duration050 ease-in-out max-h-30 mx-1'
        
        if(myN === select){
            return class_butt_selected
        }

        return class_butt
    }

    function choesclass(myN:number):string{
        
        const class_butt = 'border border-orange-500 relative inline-block px-6 py-2.5 bg-stone-600 text-white  font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-stone-700 hover:shadow-lg focus:bg-stone-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-stone-800 active:shadow-lg transition duration050 ease-in-out h-35 mx-1'
        const class_butt_selected = 'border border-violet-300 relative inline-block px-6 py-2.5 bg-stone-800 text-white  font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-stone-700 hover:shadow-lg focus:bg-stone-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-stone-800 active:shadow-lg transition duration050 ease-in-out h-35 mx-1'
        
        if(myN === select){
            return class_butt_selected
        }

        return class_butt
    }

    async function onClickbutt(myN:number){
        props.selectMenu(myN)
        await setSelect(myN)
        
        console.log(select)
    }
    return(<>
        <div className="flex flex-wrap   h-35  flex flex-wrap  items-start place-content-start " >

            <button 
                onClick={()=>onClickbutt(1)}    
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                title='Сгрупперовать и получить архив с характеристиками шума'
                className={choesclass(1)} >
                Поиск
                   
            </button>
             <button 
                // onClick={onClickbutt(2)}
                onClick={()=>onClickbutt(2)}   
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                // title='Сгрупперовать и получить архив с характеристиками шума'
                className={choesclass(2)}  >
                   Мои ИШ
                   
            </button>
             <button 
                onClick={()=>onClickbutt(3)}   
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                // title='Сгрупперовать и получить архив с характеристиками шума'
                className={choesclass(3)} >
                   Мои группы ИШ
                   
            </button>
            {props.selectedGRPName != '' && 
        <button 
            onClick={()=>onClickbutt(4)}   
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            // title='Сгрупперовать и получить архив с характеристиками шума'
            className={choesclass_2(4)} >
               {props.selectedGRPName}
               
        </button>}
  
        </div>
        </>
    )
})