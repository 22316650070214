import {Nois_param} from "./Nois_param_add"
import {Ra_nois_param} from '../models'

interface   Nois_tab_param_props {
    nois_param: Ra_nois_param

}
export function Tabl_params_nois(props:Nois_tab_param_props){
    const class_row = 'border border-amber-900 overflow-hidden'
    return(<>
    
      { props.nois_param.type && <div key = {String(props.nois_param.n)+'title'}  className='absolute bottom-0 -left-5'><p>L<sub className="font-features subs ">{props.nois_param.type}</sub></p></div>}
        <div className="grid grid-cols-9 gap-1 box-content px-1 text-xs text-center text-orange-400">
            <div key = {String(props.nois_param.n) + '63'} className={class_row}>63</div>
            <div key = {String(props.nois_param.n) + '125'} className={class_row}>125</div>
            <div key = {String(props.nois_param.n) + '250'} className={class_row}>250</div>
            <div key = {String(props.nois_param.n) + '500'} className={class_row}>500</div>
            <div key = {String(props.nois_param.n) + '1000'} className={class_row}>1000</div>
            <div key = {String(props.nois_param.n) + '2000'} className={class_row}>2000</div>
            <div key = {String(props.nois_param.n) + '4000'} className={class_row}>4000</div>
            <div key = {String(props.nois_param.n) + '8000'} className={class_row}>8000</div>
            <div key = {String(props.nois_param.n) + 'tot'} className={class_row}>tot</div>
        </div>
        <Nois_param nois_param ={props.nois_param} border_color='border-amber-900'/>
        </>
    )
}