import { API_GETALLTEG, API_URL, API_SEARCHTEGS, API_ADDTEG, API_CLICKUPTEG } from "../config";
import {Ra_teg } from '../models'
import axios  from "axios";
import { Axios, AxiosError, AxiosResponse} from "axios";
import $api from "../api/auth";
import api from "../api/auth";

class TegsService {


    static async getPopularTegs(limit:number, skip:number): Promise<AxiosResponse<Array<Ra_teg>>>   {

       const response = await api.get<Array<Ra_teg>>(API_GETALLTEG+'/'+String(limit)+'/'+String(skip))
        return  response
    }

    static async search(tegname: string,limit:number=10): Promise<AxiosResponse<Array<Ra_teg>>>{
        let tegLC = tegname.toLowerCase();
        let tegArr = tegLC.replaceAll(' ','&w=')
        const response = await api.get<Array<Ra_teg>>(API_SEARCHTEGS+'/'+ String(limit)+'?w='+tegArr)
        return  response
    }

    static async addteg(tegname: string): Promise<AxiosResponse<Array<Ra_teg>>>{
        const response = await $api.post<Array<Ra_teg>>(API_ADDTEG)
        return  response
    }

    static async clickupteg(idTeg: string): Promise<AxiosResponse>{
        const response = await $api.get<Array<Ra_teg>>(API_CLICKUPTEG + '/'+idTeg)
        return  response
    }
}
export default TegsService