import {Formuploads3, FileChose} from '../models'
import React, {FC, useEffect, useState, useContext, HTMLInputTypeAttribute} from 'react';
import Form_uploadPasport from './formupoloadpasport'
import FormuploadService from '../services/FormuploadService'
import {Context} from "../index"
import {observer} from "mobx-react-lite"
import {MAXSIZEUPLOADPASPORT} from '../config'
import RaUtilita from '../services/RaUtilita'
import { json } from 'stream/consumers';
import { Axios, AxiosError, AxiosResponse } from "axios";
function Forms_uploadPasports(){


    const formatFileSize = function (bytes:number) {
        const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
    };


    const {store} = useContext(Context);
    const [input_upload_pasp, setInput_upload_pasp] = useState(Array<FormData>)  // форма загрузки паспорта
    const [rady_forms, setRady_forms] = useState(Boolean)
    const [filechose, setFilechose] = useState(Array<FileChose>)
    // const [countfilechose, setCountfilechose] = useState(0)
    async function getFormparametrs(){
        // await store.getparametersupload()

    }





    async function changestatus_(index:number,status:number){
        await setFilechose( existingItems => {
                    const filechose_:FileChose =     {
                        file:existingItems[index].file,
                        id:existingItems[index].id,
                        key:existingItems[index].key,
                        filename:existingItems[index].filename,
                        filesize:existingItems[index].filesize,
                        formatfilesize:existingItems[index].formatfilesize,
                        fileformat:existingItems[index].fileformat,
                        status_upload:status
                    }
                  return [
                    ...existingItems.slice(0, index),
                    filechose_,
                    ...existingItems.slice(index + 1),
                  ]
                })
              
    }

    async function uploadF() {
        console.log(store.filesuploaded.length)
        for(let i=0; i<store.filesuploaded.length; i++){
            console.log(i)

            if(store.filesuploaded[i].filesize <= MAXSIZEUPLOADPASPORT){
                let newname = store.filesuploaded[i].filename
                const format:string|boolean = RaUtilita.getFormatFile(newname)
                if( typeof format === 'string'){
                    const formatAllowed = store.filesuploaded[i].fileformat
                    if(formatAllowed){
                        console.log(format)
                        newname = RaUtilita.charAllowedFileName(newname)
                       store.changestatus(i,3)

                        const response = await FormuploadService.getparameters(newname, format, store.filesuploaded[i].filesize, newname)
                        store.filesuploaded[i].key = response.data.key
                        store.changestatus(i,1)
                        const formData = new FormData()
                        formData.append('key',response.data.key)
                        formData.append('X-Amz-Credential',response.data.X_Amz_Credential)
                        formData.append('acl',response.data.acl)
                        formData.append('X-Amz-Algorithm',response.data.X_Amz_Algorithm)
                        formData.append('x-amz-meta-tag',response.data.x_amz_meta_tag)
                        formData.append('bucket',response.data.bucket)
                        formData.append('content-type',response.data.content_type)
                        formData.append('X-Amz-Date',response.data.X_Amz_Date)
                        formData.append('policy',response.data.policy)
                        formData.append('X-Amz-Signature',response.data.X_Amz_Signature)
                        

                        // formData.append('Access-Control-Request-Method','POST')
                        // formData.append('Origin','http://*')
                        // formData.append('Access-Control-Allow-Origin','http://*')
                        formData.append('file',store.filesuploaded[i].file)
                        // 
                        // formData.append('success_action_status','200')
                        
                        
                        console.log('---')
                        setInput_upload_pasp(current => [formData, ...current ])
                        console.log('status_upload',store.filesuploaded[i].status_upload)
                        
                        if(store.filesuploaded[i].status_upload==1){
                            store.changestatus(i,0)
                            const response2 = await FormuploadService.sendFormtoS3(formData)
                            if(response2.status == 204 || response2.status == 200){
                                store.changestatus(i,4)
                            }
                            else{
                                store.changestatus(i,-1)
                            }

                        }
                        

 
                        // const config = {
                        //     headers: {"enctype":"multipart/form-data"},
                                                // }
                        // console.log(response2)
                        // const data = await axios.post("https://storage.yandexcloud.net/rumidafiles1", formData, config)
                        // const resp  =await fetch("https://storage.yandexcloud.net/rumidafiles1",{
                        //     method:'POST',
                        //     body: formData,
                        //     headers: {
                        //         "enctype":"multipart/form-data",
                        //         'Access-Control-Request-Method':'POST',
                        //         'Origin':'*',
                        //         'Access-Control-Allow-Origin':'*'

                        //     }
                        // })
                        // const data = await resp.json()
                        
                    }

                }


            }
            else{
                store.changestatus(i,2)
            }
        } 
    }


    async function onChangeuploadpasport(uploadfile:any, upfilename:string){

            let arrayfile:Array<FileChose>= []
            for(let i=0; i<uploadfile.length; i++){
                let newname = String(uploadfile[i].name).toLowerCase().replaceAll(' ','_').replaceAll('-','_')
                const format:string|boolean = RaUtilita.getFormatFile(newname)
                if( typeof format === 'string'){
                    const size = formatFileSize(uploadfile[i].size)
                const fileChose:FileChose = {
                    file:uploadfile[i],
                    id:filechose.length,
                    key:'',
                    filename:newname,
                    filesize:uploadfile[i].size,
                    formatfilesize:size,
                    fileformat:format,
                    status_upload:3
                    }
                    console.log(i)
                    arrayfile.push(fileChose)
                    store.setFileupload(fileChose)
                // await setFilechose(current => [...current, fileChose])
                
                console.log(filechose.length)
                // setCountfilechose(countfilechose+1)
                }
            }
            
            // await setFilechose(arrayfile)

        
            uploadF()
        }
        // for(let i=0; i < input_upload_pasp.length; i++){
        //     await uploadFile(input_upload_pasp[i])
        // }
        
        // input_upload_pasp.map(item => await uploadFile(item))
        // const response2 = await FormuploadService.sendFormtoS3(formData)
        // console.log(response2)
    

    async function uploadFile(formData:FormData){

        try{
            const respons = await FormuploadService.sendFormtoS3(formData)
            console.log('yes send')
            console.log(respons.status)
            if(respons.status==204 || respons.status ==200){

            }
            return respons
        }
        catch(e){
            console.log('err')
            return 0
                }
    }
    useEffect(() => {
        // getFormparametrs()
        
   }, [])
    return(
        <div className='flex flex-row' key='ew'>
            <div className="uploadpasp">
                <div className="form-group">
                    <label title='pdf, jpg, jpeg, png max 2Мб' className="label rounded-lg p-2 mt-2 mb-2 border-2 border-gray-600  block cursor-pointer transition border-dashed ease-in-out delay-150  hover:-translate-y-1 hover:scale-95 hover:bg-neutral-900 hover:border-solid hover:border-neutral-500 duration-300 text-center w-[120px] ">
                    <i className="material-icons">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                    </i>
                    <span className="text-sm">Добавить файл </span>
                    <input type="file" className='opacity-0 pointer-events-none' multiple  onChange={e => onChangeuploadpasport(e.target.files, e.target.value)} accept=".jpg, .png, .jpeg, .pdf"/>
                    <p className='italic text-slate-400 text-xs'>pdf, jpg, jpeg, png max 2Мб</p>
                    
                    </label>
                </div>
            </div>
            <div className='ml-5' key='sa'>
                <p></p>
                <ul className="list-none md: list-none">
                {store.filesuploaded.map(item => <li><Form_uploadPasport key={item.id} fileCH={item} /></li> )}
                
                </ul>
            </div>
        </div>
    )
}
export default observer(Forms_uploadPasports);