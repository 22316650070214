interface lang{
    // [key_: string]: string | number | {} | undefined;
    FILELISTZERO:string
    FILENOTUPLODED:string
    TEGLISTZERO:string
    NAMEERR:string
    NAMEVARYLONG:string
    PARAMETERSNOISZERO:string
    PRIVATTRUE:string
    PRIVATFALSE:string
    MESSAGEGRPCREATION:string
    GETLINKDOWNLOAD:string
    GROUPTITLE:string
    VIEWGROUP:string
    STARTGETINGLINK:string
    ERRORGETLINK:string
    ERRORCONNECTGETLINK:string
    GRPADDSUCCESS:string
    
}
interface LocDic {
    [key: string]: string | number | {} | undefined;
    ru:lang
}
//Use
//import MessageADDNoisitem from "../message"
//    const M = new MessageADDNoisitem()
//    const Text = M.getLang('ru')
//      or          const message = new MessageADDNoisitem().getLang('ru')


export default class MessageADDNoisitem{
    
    loc = 'ru'

        ru:lang={
            FILELISTZERO: 'Прикрепите файл источника приведенных шумовых характеристик',
            FILENOTUPLODED :'Для продолжения дождитесь загрузки файлов',
            TEGLISTZERO :'Укажите минимум 1 ключевое слово для поиска',
            NAMEERR: 'Наименование указано не верно!',
            NAMEVARYLONG: '"Наименование" - не более 255 символов!',
            PARAMETERSNOISZERO: 'Добавьте параметры источника шума',
            PRIVATTRUE: 'общий доступ закрыт',
            PRIVATFALSE: 'общий доступ открыт',
            MESSAGEGRPCREATION: 'Архив с ИШ формируется. Готовая группа ИШ с архивом отобразится в разделе "Мои группы"',
            GETLINKDOWNLOAD: 'Скачать архив с ИШ',
            GROUPTITLE: 'ГРУППА ИШ',
            VIEWGROUP: 'Показать ИШ группы',
            STARTGETINGLINK: 'скачивание архива начнется автоматически',
            ERRORGETLINK: 'Ошибка на сервере.',
            ERRORCONNECTGETLINK: 'Ошибка. Проверьте подключение!',
            GRPADDSUCCESS: 'Группа успешно сформирована!'


        }
    

    setLoc(loc:string){
        this.loc = loc
    }
    getLang(loc:string){
        // this.locDic.ru.FILELISTZERO
        if(loc == 'ru'){
            return this.ru
        }
        return this.ru

        
        
        
        
    }
}