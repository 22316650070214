import {MyGrp,SourceNoisResponce, Ra_teg} from "../models"
import React, {FC, useContext, useState, useEffect} from "react"
import {Context} from "../index"
import {observer} from "mobx-react-lite"
import SearchResult from './SearchResult'
import {SourceNoisService} from "../services/SourceNoisService"
import MyGroupComponent from "./MyGroupComponent"
import Pagination from "./Pagination"

interface tegsForfoundProps{
    includeBoolBasket(id:string):boolean
    tegsForfound:Array<Ra_teg>
    selectNoiseSource(noisSource:SourceNoisResponce):any
    selectMenu:number
    selectGroup(myGroup:MyGrp):any
    mygrp:Array<MyGrp>
    slectedGRPviwe:Array<SourceNoisResponce>
    myIsh:Array<SourceNoisResponce>
    myIshloaderr:boolean
    paginationGRPTrue:boolean
    paginationMyGrpClic():any

    paginationMyIshTrue:boolean
    paginationMyIshClic():any

    paginationSearchResult:boolean
    paginationSearchResultClic():any

    textSearch:string

}

export  default observer(function SearchResultComponent (props:tegsForfoundProps) {



    const [searchResultData, SetSearchResultData] = useState(Array<SourceNoisResponce>)
    // const [myGroup, setMyGroup] = useState(Array<MyGrp>)
    const [load, setLoad] = useState(false)
    const [error, setError] = useState(false)
    const [errorMesage, setErrorMesage] = useState(String)

    const {store} = useContext(Context);

    


    async function findsourceNoisbyTegs() {
        setErrorMesage('')
        SetSearchResultData([])
        store.updateloadTrue(false) 
        setLoad(true)
        setError(false)
        console.log('SourceNoisService')
        let namesTag:string[] = []
        for(let teg_i=0;teg_i<props.tegsForfound.length; teg_i++){
            console.log(teg_i)
            namesTag.push(props.tegsForfound[teg_i].name)
        }
        try{
            const responce = await SourceNoisService.find(namesTag,props.textSearch)
            if(responce.data.length === 0){
                console.log('----------err')
                
                setLoad(false)
                if(searchResultData.length==0){
                    setError(true)
                    await setErrorMesage('Ничего не найдено')
                }else{await setError(false)}
                
                return
            }
            await setError(false)
            await SetSearchResultData(responce.data)
            setLoad(false)
            await store.updateloadTrue(false) 
        }
        catch(e:any){
            let cod =422 
            try{
                await setErrorMesage('Ничего не найдено')
                console.log('err')
                console.log(e.response?.status)
                cod = e.response?.status
                if(cod === 400){
                    await setErrorMesage('Ничего не найдено')
                }
            }catch{
                cod = 422
                await setErrorMesage('Ошибка соединения')
            }
            setLoad(false)
            await setError(true)

            store.updateloadTrue(false) 
        }            
        store.updateloadTrue(false) 
    }

    const select_item_n_Click = async(selItem:SourceNoisResponce) => {

            props.selectNoiseSource(selItem)

        
    }

    const select_group_n_Click = async(loadGroup:MyGrp) => {
        props.selectGroup(loadGroup)
    }

    const selectMenu = (myN:number)=>{
        if(props.selectMenu === myN){
            return 'visible'
        }
        return 'hidden'
    }

    async function paginationMyIsh() {
        
    }

    async function paginationMyGrp() {
        
        
    }

    return(

        <div className="basis-1/4  rounded-lg mx-4">
            <div className={selectMenu(1)}>
                {!load && searchResultData.map(nois_item => <SearchResult included={props.includeBoolBasket(nois_item.id)} nois_item={nois_item} click={select_item_n_Click} key={nois_item.id}  />)}
                {load &&  <p>Идет загрузка</p>} 
                <>{store.loadtrue_  && findsourceNoisbyTegs()}</>
                {error && <p>{errorMesage}</p>}
                {props.paginationSearchResult && <Pagination clickPagination={props.paginationSearchResultClic}/>}
            </div>
            
            <div className={selectMenu(3)}>
                {!load && props.mygrp.map(grp_item => <MyGroupComponent  group={grp_item} click={select_group_n_Click} key={grp_item.id}  />)}
                {load &&  <p>Идет загрузка</p>} 

                {props.paginationGRPTrue && <Pagination clickPagination={props.paginationMyGrpClic}/>}
            </div>
            <div className={selectMenu(2)}>
                {!load && props.myIsh.map(myish_item => <SearchResult included={props.includeBoolBasket(myish_item.id)} nois_item={myish_item} click={select_item_n_Click} key={myish_item.id} />)}
                {load &&  <p>Идет загрузка</p>} 
                {props.myIshloaderr && <p>Ошибка</p>}

                {props.paginationMyIshTrue && <Pagination clickPagination={props.paginationMyIshClic}/>}
            </div>

            <div className={selectMenu(4)}>
                {!load && props.slectedGRPviwe.map(nois_item => <SearchResult  included={props.includeBoolBasket(nois_item.id)} nois_item={nois_item} click={select_item_n_Click} key={nois_item.id}  />)}
                {load &&  <p>Идет загрузка</p>} 

            </div>
        </div>
    )
})
// export default observer(SearchResultComponent);