import axios from "axios";
import { config } from "process";
import {API_URL, S3API_URL} from '../config'
// export const API_URL = 'http://127.0.0.1:8000'
const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})


$api.interceptors.request.use( (config) => {
    if (config.headers != undefined) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
        
    }
    return config;
})

export const     api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})

export const s3api = axios.create({
    
    withCredentials: true,
    baseURL: S3API_URL
})

export default $api;