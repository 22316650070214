import $api from "../api/auth";
import { Axios, AxiosError, AxiosResponse } from "axios";
import { AuthResponse,IUserRegistration, RegistrationResponse } from "../models";
import { url } from "inspector";
import { URL_AUTH, URL_LOGOUT, URL_REGISTRATION } from "../config";
import {IUser, Ilogin} from '../models'
class AuthService {

    static async login(log:Ilogin): Promise<AxiosResponse<AuthResponse>>   {

        return $api.post(URL_AUTH,log)
    }

    static async registration(email: string, password: string,passwordconfirm:string, login:string): Promise<AxiosResponse<RegistrationResponse>>{
        const newuser:IUserRegistration={
            log:login,
            email:email,
            password1:password,
            password2:passwordconfirm}

        return $api.post<RegistrationResponse>(URL_REGISTRATION,newuser)
    }

    static async logout(): Promise<void> {
        return $api.post(URL_LOGOUT)


    }
}
export default AuthService 