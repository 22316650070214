import React, {useContext, useState, useEffect} from "react"
import {observer} from "mobx-react-lite"
import RaUtilita from '../services/RaUtilita';
interface   Action {
    getArchiveAction(nameGrp:string): any

}

export default observer(function Bargroup(props:Action){
    const [nameGrp,SetNameGrp] = useState<string>('')
    const [load, setLoad] = useState<boolean>(false)
    function onChangeNameGrp(name_val:string) {
        const name:string = RaUtilita.charAllowedFileName(name_val)
        SetNameGrp(name)
    }
    function onClickGetSH(){
        setLoad(true)
        props.getArchiveAction(nameGrp)
    }
return(
<div className="">
<form ><div className="flex flex-wrap">
            <div className=" pointer-events-auto mx-2 mb-4 ">
                <svg className="absolute text-slate-400 h-5 w-5 mt-2 ml-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" clipRule="evenodd" />
                </svg>
                <input type="text"  onChange={e =>onChangeNameGrp(e.target.value)} className='pl-7 text-blue-800 rounded-lg w-full h-9' title="Не менее 4 символов" placeholder='Введите имя группы ИШ.' 
                value={nameGrp}
                />
            </div>
            <div>
                <button onClick={()=>props.getArchiveAction(nameGrp)} 
                        type="button"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                        title='Сгрупперовать и получить архив с характеристиками шума'
                        className=" relative inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration050 ease-in-out h-35" >
                    Сгрупперовать ИШ
                </button>
            </div>
            </div></form>
</div>

)
})