import {TabParams_item_info} from "./TableParams_item_info"
import {Ra_nois_param} from '../models'

interface   Nois_tab_param_props {
    nois_param: Ra_nois_param[]


}
export function Tabl_params_nois(props:Nois_tab_param_props){
    const class_row = 'border border-gray-900 overflow-hidden'
    const class_td = 'p-1 border border-gray-600 overflow-hidden'
    return(
        <div className='relative' >
       
            <div className="grid grid-cols-9 gap-1 box-content px-1 text-xs text-center text-orange-200">
                
            
                <table className="border border-gray-600 mt-2 mb-2 cursor-text">
                    <thead>
                    <tr className="text-blue-500">
                        <td className={class_td}>63</td>
                        <td className={class_td}>125</td>
                        <td className={class_td}>250</td>
                        <td className={class_td}>500</td>
                        <td className={class_td}>1000</td>
                        <td className={class_td}>2000</td>
                        <td className={class_td}>4000</td>
                        <td className={class_td}>8000</td>
                        <td className={class_td}>дб экв.</td>
                    </tr></thead>
                    <tbody>
                    {props.nois_param.map(item => <TabParams_item_info nois_param={item} td={class_td} key={item.n}/>)}
                    </tbody>
                </table>
           
            </div>
            
        </div>
    )

}