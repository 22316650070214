import React, {useEffect, useState, useContext} from 'react';
import {Ra_teg} from '../models'
import { AxiosError } from 'axios';
import TegsService from '../services/TegsService';
import RaUtilita from '../services/RaUtilita';
import {observer} from "mobx-react-lite"
import { Context } from ".."
import {Item_teg} from "./Item_teg"


interface   Teg_props {
  Selected(selectedTags:Ra_teg, action:number):any
  searchTextProps(text:string):any

} 
export function Search (props: Teg_props) {

  const {store} = useContext(Context);
  const [tegs_for_filtr, setTegs_for_filtr] = useState(Array<Ra_teg>)
  const [tegs_Found, setTegs_Found] = useState(Array<Ra_teg>)
  const [loadTeg, setLoadTeg] = useState<boolean>(false)  //состаяние загрузка тегов
  const [errorloadTeg, setErrorloadTeg] = useState<boolean>(false)  //состаяние ошибка загрузки тегов
  const [emptyFoundTeg, setEmptyFoundTeg] = useState<boolean>(false)  //состаяние не найдено тегов по запросу
  const [searchImputVal, setSearchImputVal] = useState<string>('')


  const add_teg_click = async(addTag:Ra_teg) => {    // клик по тэгу
    let true_add  = true
    for(let i = 0;i<tegs_for_filtr.length;i++){
        if(tegs_for_filtr[i]._id === addTag._id){true_add=false}
    }
    if(true_add){
        const response = await TegsService.clickupteg(addTag._id)
        await setTegs_for_filtr(current => [...current, addTag])
        await setSearchImputVal('')
        searchTeg('')
    }
    props.Selected(addTag,1)    
  }

const rm_teg_click = async(addTag:Ra_teg) => {    // клик по тэгу
    setTegs_for_filtr(tegs_for_filtr.filter(p => p._id !== addTag._id));

    props.Selected(addTag,0)
}

  async function getPopularTegs(){
    try{
        // setEmptyFoundTeg(false)
        setErrorloadTeg(false)
        setLoadTeg(true)

        const response = await TegsService.getPopularTegs(6,0)
        await setTegs_Found(response.data)
        setLoadTeg(false)
    }
    catch{
        setLoadTeg(false)
        setErrorloadTeg(true)
    }

}

  async function searchTeg(teg:string) {
   
    try{
      setLoadTeg(false)
      setSearchImputVal(teg)
      
      setErrorloadTeg(false)
      setEmptyFoundTeg(false)
      props.searchTextProps(teg)
      console.log()


        setLoadTeg(true)
        // props.searchTextProps(teg)
        await store.updateloadTrue(true) 
        
        const tegAllowed = RaUtilita.charAllowed(teg)
        const response = await TegsService.search(tegAllowed)
        
        
        await setLoadTeg(false)
        if(response.data.length == 0){
            // Тегов не найдено
            await setEmptyFoundTeg(true)
            await getPopularTegs()
            }
          else{
            await setTegs_Found(response.data)
          }
          if(teg.length === 0){
            await setEmptyFoundTeg(false)
            getPopularTegs()
          }
      
      // if(tegs_Found.length === 0){
      //      await setEmptyFoundTeg(true)
      //      await getPopularTegs()
      // }
        
    }
    catch(e:unknown){
      const error = e as AxiosError
      await setLoadTeg(false)
      await setErrorloadTeg(true)
      }
  }
  useEffect(() => {
    getPopularTegs()

  
}, [])
  return (<>
        <form >
            <div className=" pointer-events-auto mx-1 mb-4 min-w-max">


                <div className="flex w-full ">
                <div className="grow">                
                  <svg className="absolute text-slate-400 h-5 w-5 mt-2 ml-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                  <input type="text"  className='pl-7 text-blue-800 rounded-lg w-full '  placeholder='поиск' 
                    onChange={e => searchTeg(e.target.value)}
                    value={searchImputVal}
                  /></div>
                {/* <div className="flex-none"><button title='Поиск по логину пользователя'
                  onClick={() => setSearchImputVal('@')}
                  type="button" className="inline-block
                  px-4 py-2.5 bg-zinc-700
                  border border-zinc-500
                  leading-tight uppercase rounded
                  shadow-md hover:bg-zinc-600 hover:shadow-lg
                  focus:bg-stone-600 focus:shadow-lg focus:outline-none 
                  focus:ring-0 active:bg-zinc-800 active:shadow-lg 
                  transition duration050 ease-in-out ml-2">@</button></div> */}
                {/* <div className="flex-none"><button title='Поиск по тэгам'
                  type="button" className="inline-block 
                  px-4 py-2.5 bg-zinc-700
                  border border-zinc-500
                  leading-tight uppercase rounded
                  shadow-md hover:bg-zinc-600 hover:shadow-lg
                  focus:bg-stone-600 focus:shadow-lg focus:outline-none 
                  focus:ring-0 active:bg-zinc-800 active:shadow-lg 
                  transition duration050 ease-in-out ml-2">#</button></div> */}
            </div>

            </div>
            
        </form>
        <div >
        {loadTeg && <div><img width={50} src="dwld_wait_please2.gif" alt="" /></div>}
        {emptyFoundTeg && <p className='px-2 text-emerald-500'>Теги по вашему запросу не найдены</p>}
        <div className="flex flex-wrap mb-2">
                {tegs_for_filtr.map(teg_item => <Item_teg pref="tg1_" tegs={teg_item} key={teg_item._id} onClick_={rm_teg_click} addclass='text-violet-300 border border-violet-500 hover:bg-gray-600 '></Item_teg>  ) }
        </div>
        <div className="flex flex-wrap">
            {tegs_Found.map(teg_item => <Item_teg pref="tg2_" tegs={teg_item} key={teg_item._id} onClick_={add_teg_click}></Item_teg>  ) }
        </div>

        </div>
      </>
      );
    }
  